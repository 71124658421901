import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BarcodeFormat } from "@zxing/library";
import { BehaviorSubject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import { CouponCodeEnquObj } from "../dto/coupon-inquiry-request";
import { environment } from "src/environments/environment";
import { CouponInquiryService } from "../services/coupon-inquiry.service";

@Component({
  selector: "app-scan-code",
  templateUrl: "./scan-code.component.html",
  styleUrls: ["./scan-code.component.css"],
})
export class ScanCodeComponent implements OnInit {
  couponCodeEnquiryRequest: CouponCodeEnquObj;
  @ViewChild("scanner", { static: false })
  scanner: ZXingScannerComponent;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  selectedDevice: MediaDeviceInfo = null;
  desiredDevice: object;
  route: ActivatedRoute;
  dateNow: Date;
  device: MediaDeviceInfo;
  hostURL: String;
  scannerEnabled: Boolean;
  isReset: String;
  faSignOut = faSignOut;
  localStorage: any;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;
  isOpenCam: boolean;

  qrResultString: string;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  constructor(
    private readonly _dialog: MatDialog,

    private couponInquiryService: CouponInquiryService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.route = route;
    this.hostURL = environment.apiURL;
    this.couponCodeEnquiryRequest = {
      COUPONCODEENQREQ: {
        HEADERINFO: {
          REQUESTID: "",
          PCFLAG: "",
          STORENUMBER: "",
          SOURCETYPE: "",
          SUBSIDIARYNUMBER: "",
          RECEIPTNUMBER: "",
          ENTEREDAMOUNT: "", //for inquiry
          RECEIPTAMOUNT: "",
        },
        COUPONCODEINFO: {
          COUPONNAME: "",
          COUPONCODE: "",
          STORENUMBER: "",
          SUBSIDIARYNUMBER: "",
          RECEIPTNUMBER: "",
          ENTEREDAMOUNT: "", //for inquiry
          RECEIPTAMOUNT: 0, //for redemption
          CUSTOMERID: "",
          PHONE: "",
          EMAIL: "",
          DOCSID: "",
          DISCOUNTAMOUNT: "", //for smart promotion
          CARDNUMBER: "",
          APPLIEDCOUPONS: [],
        },
        PURCHASEDITEMS: [],
        USERDETAILS: {
          USERNAME: "",
          ORGID: "",
          TOKEN: "",
        },
      },
    };
  }

  clearResult(): void {
    this.qrResultString = null;
  }
  ngOnInit(): void {
    this.isOpenCam = false;
    this.dateNow = new Date();
    this.currentDevice = null;
    this.scannerEnabled = false;
    this.availableDevices;
    this.isReset = this.route.snapshot.queryParamMap.get("reset");
    this.localStorage = JSON.parse(localStorage.getItem("user"));
    if (!this.localStorage) {
      this.router.navigate(["/login"]);
    }
    if (this.isReset === "true") {
      this.doReset();
    }
  }

  /*  interval;
  
    startTime() {
      this.interval = setTimeout(() => {
        alert("Alert actiItem sync Success")
      }, 1000);
    }
  */
  doReset() {
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.HEADERINFO.REQUESTID =
      this.dateNow.toString();
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.COUPONCODE =
      "ALLCODES";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.STORENUMBER =
      "101";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.PHONE = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTNUMBER =
      "20220225181900";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CARDNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.SUBSIDIARYNUMBER =
      "1";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTAMOUNT = 435;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DOCSID = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DISCOUNTAMOUNT =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.PURCHASEDITEMS = [];
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.EMAIL = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CUSTOMERID =
      "";

    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.USERNAME =
      environment.userName;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.ORGID =
      environment.orgID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.TOKEN =
      environment.token;
    console.log(JSON.stringify(this.couponCodeEnquiryRequest));
    this.couponInquiryService
      .couponInquiry(this.couponCodeEnquiryRequest, this.hostURL)
      .subscribe(
        (resp) => {
          console.log(JSON.stringify(resp));
          this.pushItems(resp);

          alert("Item sync Success");
        },
        (err: any) => {
          console.log("Failure Response");
        }
      );
  }

  pushItems(coupons) {
    var items = [];
    var getItems = coupons.COUPONCODERESPONSE.COUPONDISCOUNTINFO;
    for (var i = 0; i < getItems.length; i++) {
      if (typeof getItems[i].DISCOUNTINFO !== "undefined") {
        for (var j = 0; j < getItems[i].DISCOUNTINFO.length; j++) {
          if (
            typeof getItems[i].DISCOUNTINFO[j].ITEMCODEINFO[0] !== "undefined"
          ) {
            items.push(getItems[i].DISCOUNTINFO[j].ITEMCODEINFO[0]);
            getItems[i].DISCOUNTINFO[j].ITEMCODEINFO[0].QUANTITY = "1";
          }
        }
      }
    }
    const uniqueArray = items.filter((item, index) => {
      const _items = JSON.stringify(item);
      return (
        index ===
        items.findIndex((obj) => {
          return JSON.stringify(obj) === _items;
        })
      );
    });
    localStorage.setItem("items", JSON.stringify(uniqueArray));
  }

  onCamerasFound(devices: MediaDeviceInfo[]) {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);

    for (let index = 0; index < this.availableDevices.length; index++) {
      if (
        this.availableDevices[index].label.toLowerCase().includes("hp") ||
        this.availableDevices[index].label.toLowerCase().includes("rear") ||
        this.availableDevices[index].label.toLowerCase().includes("back")
      ) {
        const device = this.availableDevices.find(
          (x) => x.deviceId === this.availableDevices[index].deviceId
        );
        this.currentDevice = device || null;
        this.scanner.device = device || null;
        this.selectedDevice = device || null;
        break;
      }
    }
    /*for (let device of devices) {
      if (/back|rear|hp/gi.test(device.label)) {               
          this.currentDevice = device;
          this.hasDevices = Boolean(devices && devices.length);
          this.scanner.device = device;
      }
    }*/
    /*
    
    for (const device of devices) {

    
      if (/back|rear/gi.test(device.label)) {               
          this.currentDevice = device;
          break;
      }
    }
    let backCams = this.availableDevices.filter((c) => c.label.includes('back')); 
      if (backCams.length > 0) { 
        this.currentDevice = backCams[backCams.length - 1]; 
      }*/
  }

  ondeviceChange(currentDevice) {
    this.currentDevice = currentDevice || null;
    this.scanner.device = currentDevice || null;
    this.selectedDevice = currentDevice || null;
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.router.navigate(["/loyalty-redemption"], {
      queryParams: { membership: this.qrResultString },
    });
  }

  enterMobile(MobileNumber: any) {
    this.qrResultString = MobileNumber.value;
    if (this.qrResultString.length > 11) {
      this.router.navigate(["/loyalty-redemption"], {
        queryParams: { membership: this.qrResultString },
      });
    } else {
      this.router.navigate(["/loyalty-redemption"], {
        queryParams: { mobile: this.qrResultString },
      });
    }
  }

  onDeviceSelectChange(selected: string) {
    this.scannerEnabled = true;
    const device = this.availableDevices.find((x) => x.deviceId === selected);
    this.scanner.device = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }
  openCam() {
    this.scanner.scannerEnabled = true;
    this.onDeviceSelectChange(this.selectedDevice.deviceId);
  }
  logOut() {
    localStorage.removeItem("user");
    localStorage.removeItem("items");
    this.router.navigate(["/login"]);
  }
}
