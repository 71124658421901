// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  title: "Production Environment Heading",
  apiURL: "qcapp.optculture.com",
  orgID: "GAP",
  userName: "GAP",
  token: "H0KJOZXKEFR9RSTR",
  sourceType: "LoyaltyApp",
  items: [
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM001",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM002",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM003",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM004",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM005",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM006",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM007",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM008",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM009",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM010",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM011",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM012",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM013",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM014",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM015",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM016",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM017",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM018",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM019",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
    {
      ITEMPRICE: 1,
      ITEMCODE: "OCITEM020",
      QUANTITY: "1",
      ITEMDISCOUNT: "0",
    },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
