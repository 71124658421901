import { Component, OnInit } from '@angular/core';
import {LoyaltyOtpService} from '../services/loyalty-otp.service'
import {LoyaltyOTPRequest} from '../dto/LoyaltyOTPRequest'
import {MatDialog} from '@angular/material/dialog'


@Component({
  selector: 'app-verification-otp',
  templateUrl: './verification-otp.component.html',
  styleUrls: ['./verification-otp.component.css']
})
export class VerificationOtpComponent implements OnInit {

  loyaltyOTPRequest:LoyaltyOTPRequest;
  otpCode:String;
  constructor(private loyaltyOtpService:LoyaltyOtpService,private dialog:MatDialog) {
    this.otpCode = ''
    this.loyaltyOTPRequest={
      header:{
        requestId:'',
        requestType:'',
        requestDate:'',
        pcFlag:'',
        subsidiaryNumber:'',
        storeNumber:'',
        employeeId:'',
        terminalId:'',
        docSID:'',
        receiptNumber:'',
        sourceType:''
        },
      otpCode:'',
      customer:{
        subsidiaryNumber:'',
        deviceType:'',
        createdDate:'',
        customerId:'',
        instanceId:'',
        phone:'',
        emailAddress:'',
        firstName:'',
        lastName:'',
        city:'',
        state:'',
        postal:'',
        country:'',
        gender:'',
        birthday:'',
        anniversary:'',
        addressLine1:'',
        addressLine2:'',
      },
      user:{
        userName:'',
        organizationId:'',
        token:''
       }
       
       
       
  
  
     }
  
   }

  ngOnInit(): void {
  }
  loyaltyOTP(otpCode:any){
    this.loyaltyOTPRequest.header.requestId='123'
    this.loyaltyOTPRequest.header.requestDate='2014-06-16 12:12:12'
    this.loyaltyOTPRequest.header.requestType='Acknowledge'
    this.loyaltyOTPRequest.header.storeNumber='10'
    this.loyaltyOTPRequest.header.docSID='10200'
  
    this.loyaltyOTPRequest.otpCode=otpCode.value

    this.loyaltyOTPRequest.customer.phone='8947962685'
    this.loyaltyOTPRequest.customer.emailAddress=''
  
    this.loyaltyOTPRequest.user.userName='TestingPR'
    this.loyaltyOTPRequest.user.organizationId='TestingPR'
    this.loyaltyOTPRequest.user.token='64SX757BIBWIKV5N'
  
    console.log(this.loyaltyOTPRequest)
    console.log('resp===>'+JSON.stringify(this.loyaltyOTPRequest));
    this.loyaltyOtpService.loyaltyOtp(this.loyaltyOTPRequest, '').subscribe((resp)  =>
    { console.log(resp)
     
      this.dialog.closeAll();
      //this.dialog.open(OtpSucessDialog);     
        },(err: any) => {
      console.log('Failure Response');
    })
  
  }
  

}

