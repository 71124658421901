import { Injectable } from '@angular/core';
import {LoyaltyOTPRequest} from '../dto/LoyaltyOTPRequest'
import {LoyaltyOTPResponse} from '../dto/LoyaltyOTPResponse'
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyOtpService {

  constructor(private httpClient: HttpClient) { }


  loyaltyOtp(loyaltyOTPRequest: LoyaltyOTPRequest, host){
    return this.httpClient.post<LoyaltyOTPResponse>('https://' + host + '/subscriber/OCOTPService.mqrm', loyaltyOTPRequest);
  }

}
