<!-- Default unchecked disabled -->
<div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="defaultUncheckedDisabled2" name="disabledGroupExample" >
  <label class="custom-control-label" for="defaultUncheckedDisabled2">Default unchecked disabled</label>
</div>

<!-- Default checked disabled -->
<div class="custom-control custom-radio">
  <input type="radio" class="custom-control-input" id="defaultCheckedDisabled2" name="disabledGroupExample" >
  <label class="custom-control-label" for="defaultUncheckedDisabled2"></label>
</div>