import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginRequest } from '../dto/login-request';
import { LoginResponse } from '../dto/login-response';
import { LoginService } from '../services/login.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm = this.formBuilder.group({
    userID: '',
    password: ''
  });
  loginRequest: LoginRequest;
  loginResponse: LoginResponse;
  session: {
    userID:String,
    token:String,
  }
  Errormsg: String;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
  ) { 
    this.loginRequest = {
      header:{
        requestId:new Date().toISOString(),
        requestDate:new Date().toISOString(),
        docSID:JSON.stringify(Date.now()),
        sourceType:'LoyaltyApp'
        },
      user:{
        userName:'',
        organizationId:environment.orgID,
        password:''
      }
    };
    this.session = {
      userID:'',
      token:'',
    };
    this.loginResponse = {
      status: {
            errorCode: '',
            message: '',
            status: ''
        },
        APIToken: ''
      };
      this.Errormsg = '';
  }

  ngOnInit(): void {
    
  }
  onSubmit(): void {
    //console.log('Your order has been submitted', this.loginForm.value.userID);
    this.loginRequest.user.userName = this.loginForm.value.userID;
    this.loginRequest.user.password = this.loginForm.value.password;
    if(this.loginRequest.user.password !== '' && this.loginRequest.user.userName !==''){
      this.loginService
        .login(this.loginRequest)
        .subscribe(
          resp => {
            this.loginResponse = resp;
            if(resp != null && this.loginResponse.status.status === 'Success'){
              this.session.userID = this.loginForm.value.userID;
              this.session.token = this.loginResponse.APIToken;
              localStorage.setItem('user',JSON.stringify(this.session));
              this.router.navigate([''],{ queryParams: {reset:'true'}})
              console.log(this.loginResponse);
            }
            else{
              this.Errormsg = 'Invalid user credentials.';
              console.log(this.loginResponse);
            }
          },
          (err: any) => {
            this.Errormsg = err;
            console.log(err);
          }
        );
    }else{
        this.Errormsg = 'Please ensure the fileds are not empty';
    }
  }

}
