<form id="loginForm"  [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div id="model-content">
      <!-- <div class="modal-header">
            <div class="row">
                <h1>OptCulture</h1>
            </div>
        </div> -->
      <div class="modal-body inheritOverflow pt-0 pb-0">
        <div class="row">
          <div class="brand">
              <div class="hello">
                
              </div>
          </div>
          <div class="col-12">
            <div class="row">
                <div class="col-12 text-center">
                <img src="./assets/images/logo.png" />
                </div>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-12">
            <div class="login" >
                <h2 class="logintxt">Login</h2> 
                <div class="line-8"></div>      
                <div class="form-group">
                    User ID
                    <input type="text" class="form-control input"  formControlName="userID" id="userID" placeholder="Enter User ID" required="required">
                </div>
                <div class="form-group">
                    Password
                    <input type="password" class="form-control input" placeholder="Enter Password" id="password" formControlName="password" required="required">
                </div>
                <div class="submit">
                  <button
                  type="submit"
                  class="btn btn-primary mb-2"
                >
                  Login
                </button>
                <div *ngIf="Errormsg" class="pt-2 text-danger">
                  {{Errormsg}}
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
      <div class="modal-footer">
        
      </div>
    </div>
  </form>
  
