<div class="container-fluid">
    <div *ngIf="!isFilled" class="row">
      <div class="col-sm-12 col-md-6 bg-left">
        <div class="logo"><img src ="./../../assets/images/logo-v2.png" /></div>
        <div class="hello">Hello! <br> Can I Have Your Number?</div>
      </div>
      <div class="col-sm-12 col-md-6 bg-right">
       <div class="text-center">
        <div class="btn-group-vertical ml-4 mt-4" role="group" aria-label="Basic example">
            <div class="btn-group">
                <input class="text-center form-control-lg mb-2" id="code" type="number" #MobileNumber [(ngModel)]="mobile" placeholder="ENTER PHONE NUMBER">
            </div>
            <div class="btn-group">
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value + '1';">1</button>
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value + '2';">2</button>
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value + '3';">3</button>
            </div>
            <div class="btn-group">
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value + '4';">4</button>
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value + '5';">5</button>
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value + '6';">6</button>
            </div>
            <div class="btn-group">
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value + '7';">7</button>
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value + '8';">8</button>
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value + '9';">9</button>
            </div>
            <div class="btn-group">
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value.slice(0, -1);">&lt;</button>
                <button type="button" class="btn btn-outline-secondary py-3" onclick="document.getElementById('code').value=document.getElementById('code').value + '0';">0</button>
                <button type="button" class="btn btn-primary py-3" (click)="go(MobileNumber)">Go</button>
            </div>
        </div>

        <div class="modal-body pt-0 pb-0 no-bg">
            <ngb-alert
              #selfClosingAlert
              *ngIf="successMessage"
              [type]="alertType"
              (closed)="successMessage = ''"
              ><div [innerHtml]="successMessage"></div>
            </ngb-alert>
          </div>
       </div>
      </div>
    </div>
    <div *ngIf="isFilled && !isRespond" class="row">
      <div class="col-sm-12 col-md-12 bg-full" *ngIf="!askRegister">
        <div class="go-back" (click)="edit()">Go Back </div>
        <div class="welcome">WELCOME TO GAP VIP SOUTH AFRICA</div>
        <div class="box-mobile">
          <div class="row">
              <div class="col-3 col-md-3">
                  <fa-icon class="user" [icon]="faCircleUser"></fa-icon>
              </div>
              <div class="col-5 col-md-5">
                 <div class="mobile-txt">Your Number <br>{{mobile}}</div> 
              </div>
              <div class="col-4 col-md-4 mt-4"><button type="button" class="btn btn-primary btn-primary1" (click)="edit()">Edit</button></div>
          </div>
        </div>
        <div class="modal-body pt-0 pb-0 no-bg">
          <ngb-alert
            #selfClosingAlert
            *ngIf="successMessage"
            [type]="alertType"
            (closed)="successMessage = ''"
            ><div [innerHtml]="successMessage"></div>
          </ngb-alert>
        </div>
        <div class="confirm-wrp">
          <button type="button" class="btn btn-primary py-3" (click)="couponInquiry()">Confirm</button>
        </div>
      </div>

      <div class="col-sm-12 col-md-12 bg-full" *ngIf="askRegister">
        <div *ngIf="!OTOinput">
          <div class="go-back" (click)="edit()">Go Back </div>
          <!-- <div class="welcome">WELCOME TO THE GAP GOOD REWARDS PROGRAMME</div> -->
          <div class="sub-txt">Member does not exists. Please register</div>
          <div class="box-mobile-form">
            <div class="row">
                <div class="col-sm-12 col-md-12">
                  <form>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label" for="firstName">First Name <span class="text-danger">*</span></label>
                        <input type="text" id="firstName"  class="form-control" #firstName />
                        <span class="error" *ngIf="firstNameError">Please enter First Name</span>
                      </div>
                    </div>
                    <div class="d-flex flex-row align-items-center mb-4">
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label" for="lastName">Last Name <span class="text-danger">*</span></label>
                        <input type="text" id="lastName" class="form-control" #lastName />

                        <span class="error" *ngIf="lastNameError">Please enter Last Name</span>
                      </div>
                    </div>
                    <div class="d-flex flex-row align-items-center mb-4">
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label" for="mobile">Mobile Number <span class="text-danger">*</span></label>
                        <input type="text" id="mobile"  #mobile value="{{mobileNumber}}" class="form-control" />

                        <span class="error" *ngIf="mobileError">Please enter Mobile Number</span>
                      </div>
                    </div>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label" for="email">Email <span class="text-danger">*</span></label>
                        <input type="email" id="email" class="form-control"  #emailAddress />

                        <span class="error" *ngIf="emailAddressError">Please enter Email Address</span>
                        <span class="error" *ngIf="emailAddressValidError">Please enter valid Email Address</span>
                      </div>
                    </div>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label" for="dob" >Date Of Birth</label>
                        <input 
                        id="dob" 
                        type="date" 
                        class="form-control" 
                        #dob />
                      </div>
                    </div>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label" for="anniversary" >Anniversary</label>
                        <input 
                        id="anniversary" 
                        type="date" 
                        class="form-control" 
                        #anniversary />
                      </div>
                    </div>
                    <div class="d-flex flex-row align-items-center mb-4">
                      <div class="form-outline flex-fill mb-0">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" #info><span class="text-danger">*</span> The information I provided is true and correct
                          </label>
                          <br>
                        <span class="error" *ngIf="infoError">Please check the required field</span>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" #decision><span class="text-danger">*</span>  I made a willing and informed decision to become a member of Loyalty Program
                           
                          </label>
                          <br>
                        <span class="error" *ngIf="decisionError">Please check the required field</span>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" #terms><span class="text-danger">*</span>  I accept the <a (click)="termsClick()" class="terms-link" style="cursor: pointer;">terms and conditions</a> of the Loyalty Program
                          </label>

                        <span class="error" *ngIf="termsError">Please check the required field</span>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" #promotions><span class="text-danger">*</span> I agree to receive marketing information about future promotions, offers and communications.
                          </label>

                        <span class="error" *ngIf="promotionsError">Please check the required field</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                      <button type="button" class="btn btn-primary btn-lg" (click)="submitFrom(firstName,lastName, mobile, emailAddress, dob, anniversary, info, decision, terms, promotions)">Register</button>
                    </div>

                  </form>
                </div>
            </div>
          </div>
          <div class="modal-body pt-0 pb-0 no-bg">
            <ngb-alert
              #selfClosingAlert
              *ngIf="successMessage"
              [type]="alertType"
              (closed)="successMessage = ''"
              ><div [innerHtml]="successMessage"></div>
            </ngb-alert>
          </div>
          <div class="confirm-wrp">
            <!-- <button type="button" class="btn btn-primary py-3" (click)="couponInquiry()">Confirm</button> -->
          </div>
        </div>
        <div *ngIf="OTOinput">
          <div class="go-back" (click)="edit()">Go Back </div>
          <!-- <div class="welcome">WELCOME TO THE GAP GOOD REWARDS PROGRAMME</div> -->
          <div class="sub-txt">Please enter the one time password to verify your account</div>
          <div class="box-mobile-form">
            <div class="row">
                <div class="col-sm-12 col-md-12">
                  <form>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label" for="otp">Enter OTP</label>
                        <input type="number" id="otp"  maxlength="4" size="4" class="form-control" #otp />
                      </div>
                    </div>
                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                      <button type="button" class="btn btn-primary btn-lg" (click)="sendOtp('Acknowledge',otp.value)">Submit</button>
                    </div>

                  </form> 
                </div>
            </div>
          </div>
          <div class="modal-body pt-0 pb-0 no-bg">
            <ngb-alert
              #selfClosingAlert
              *ngIf="successMessage"
              [type]="alertType"
              (closed)="successMessage = ''"
              ><div [innerHtml]="successMessage"></div>
            </ngb-alert>
          </div>
        </div>
        
      </div>
      </div>
      <div *ngIf="isFilled && isRespond" class="row">
        <div class="col-sm-6 col-md-6 bg-left2">
          <div class="hello2">Hello <span class="orange">{{customerName}},</span></div>
          
          <div class="redeemable">Redeemable Currency Available: <span class="green">{{radeemableBalance}}</span></div>


          <div class="confirm-wrp">
            <button type="button" class="btn btn-primary py-3" (click)="close()">Close</button>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 bg-right2">
            <!-- <div class="avdis">AVAILABLE DISCOUNTS</div> -->
         <div class="text-center visiblea">
          <div class="display" [innerHTML]="display"></div>
          <div class="redeem-desc">{{redeemDesc}}</div>
          <div class="issuance-desc">{{issuanceDesc}}</div>
            <!-- <div *ngFor="let data of customerCouponResponseArray" class="pt-3 white-box">
                <div class="card">
                    <span class="attr">{{data.APPLYATTRIBUTES}}</span>
                    <p class="vouchers-img-content"><strong>{{data.DESCRIPTION}} {{data.COUPONCODE}} . Add Item to Avail Discount.</strong></p>
                </div>
            </div> -->
         </div>
        </div>
      </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Terms and Conditions</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div [innerHTML]="termsAndCond"></div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
  </ng-template>
