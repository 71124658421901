import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { CouponCodeEnquObj } from "../dto/coupon-inquiry-request";
import { CouponInquiryService } from "../services/coupon-inquiry.service";
import { environment } from "src/environments/environment";
import { faCircleUser, faL } from "@fortawesome/free-solid-svg-icons";
import { NgbAlert, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EnrollService } from "../services/enroll.service";
import { OtpService } from "../services/otp.service";
import { TermsService } from "../services/terms-conditions.service";

@Component({
  selector: "app-enroll",
  templateUrl: "./enroll.component.html",
  styleUrls: ["./enroll.component.css"],
})
export class EnrollComponent implements OnInit {
  @ViewChild("selfClosingAlert", { static: false }) selfClosingAlert: NgbAlert;
  @ViewChild("content") modalContent: ElementRef;
  couponCodeEnquiryRequest: CouponCodeEnquObj;
  dateNow: Date;
  isFilled: boolean;
  mobile: string = "";
  mobileNumber: string = "";
  faCircleUser = faCircleUser;
  customerCouponResponseArray: any;
  firstNameError: boolean = false;
  lastNameError: boolean = false;
  mobileError: boolean = false;
  termsAndCond: string;
  decisionError: boolean = false;
  termsError: boolean = false;
  promotionsError: boolean = false;
  infoError: boolean = false;
  emailAddressError: boolean = false;
  emailAddressValidError: boolean = false;
  customerName: String;
  dob: string = "";
  info: boolean = false;
  decision: boolean = false;
  terms: boolean = false;
  promotions: boolean = false;
  anniversary: string = "";
  otpResponse: any;
  display: any;
  askRegister: boolean = false;
  correctOTP: any;
  emailAddress: string = "";
  firstName: string = "";
  lastName: string = "";
  password: string = "";
  isRespond: boolean;
  radeemableBalance: String;
  issuanceDesc: String;
  redeemDesc: String;
  successMessage: String;
  alertType: string;
  OTOinput: boolean = false;
  enrollReq = {
    header: {
      requestId: "",
      requestDate: "",
      docSID: "",
      sourceType: "LoyaltyApp",
      terminalId: "",
      storeNumber: "L-APP",
      employeeId: "",
      pcFlag: "false",
      receiptNumber: "",
      subsidiaryNumber: "",
      requestType: "Acknowledge",
    },
    membership: {
      issueCardFlag: "Y",
      cardNumber: "",
      phoneNumber: "",
      cardPin: "",
      fingerprintValidation: "false",
    },
    customer: {
      firstName: "",
      lastName: "",
      phone: "",
      emailAddress: "",
      password: "",
      birthday: "",
      anniversary: "",
      addressLine1: "",
      addressLine2: "",
      customerId: "",
      gender: "",
      postal: "",
      UDF1: "Y",
      UDF2: "Y",
      UDF3: "Y",
      UDF4: "Y",
      state: "",
      instanceId: "",
      deviceType: "web",
    },
    user: {
      userName: environment.userName,
      organizationId: environment.orgID,
      token: environment.token,
    },
  };
  otpReq = {
    header: {
      requestId: "",
      requestDate: "",
      docSID: "",
      sourceType: "LoyaltyApp",
      terminalId: "",
      storeNumber: "L-APP",
      employeeId: "",
      pcFlag: "false",
      receiptNumber: "",
      subsidiaryNumber: "",
      requestType: "Issue",
    },
    user: {
      userName: environment.userName,
      organizationId: environment.orgID,
      token: environment.token,
    },
    customer: {
      phone: "",
      emailAddress: "",
      addressLine1: "",
      addressLine2: "",
      birthday: "",
      customerId: "",
      gender: "",
      postal: "",
      state: "",
      anniversary: "",
    },
    otpCode: "",
    issueOnAction: "",
  };

  constructor(
    private couponInquiryService: CouponInquiryService,
    private enrollService: EnrollService,
    private termsService: TermsService,
    private modalService: NgbModal,
    private otpService: OtpService
  ) {
    this.dateNow = new Date();
    this.isFilled = false;
    this.isRespond = false;
    this.mobile = "";
    this.customerName = "";
    this.radeemableBalance = "";
    this.display = "";
    this.redeemDesc = "";
    this.radeemableBalance = "";
    this.couponCodeEnquiryRequest = {
      COUPONCODEENQREQ: {
        HEADERINFO: {
          REQUESTID: "",
          PCFLAG: "",
          STORENUMBER: "",
          SOURCETYPE: "",
          SUBSIDIARYNUMBER: "",
          RECEIPTNUMBER: "",
          ENTEREDAMOUNT: "", //for inquiry
          RECEIPTAMOUNT: "",
        },
        COUPONCODEINFO: {
          COUPONNAME: "",
          COUPONCODE: "",
          STORENUMBER: "",
          SUBSIDIARYNUMBER: "",
          RECEIPTNUMBER: "",
          ENTEREDAMOUNT: "", //for inquiry
          RECEIPTAMOUNT: 0, //for redemption
          CUSTOMERID: "",
          PHONE: "",
          EMAIL: "",
          DOCSID: "",
          DISCOUNTAMOUNT: "", //for smart promotion
          CARDNUMBER: "",
          APPLIEDCOUPONS: [],
        },
        PURCHASEDITEMS: [],
        USERDETAILS: {
          USERNAME: "",
          ORGID: "",
          TOKEN: "",
        },
      },
    };
  }

  ngOnInit(): void {}
  go(MobileNumber: any) {
    if (MobileNumber.value !== "") {
      this.mobile = MobileNumber.value;
      this.mobileNumber = this.mobile;
      this.isFilled = true;
      this.isRespond = false;
    } else {
      this.successMessage = "Please enter Mobile number";
      this.alertType = "danger";
      setTimeout(() => {
        this.successMessage = "";
        this.customerName = "";
      }, 3000);
    }
  }
  edit() {
    this.isFilled = false;
    this.askRegister = false;
  }
  close() {
    this.isFilled = false;
    this.isRespond = false;
    this.askRegister = false;
    this.mobile = "";
  }
  open() {
    this.modalService
      .open(this.modalContent, { ariaLabelledBy: "modal-basic-title" })
      .result.then();
  }
  couponInquiry() {
    this.OTOinput = false;
    this.askRegister = false;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.HEADERINFO.REQUESTID =
      new Date().toISOString();

    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.COUPONCODE =
      "ALL";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.STORENUMBER =
      "1";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.PHONE =
      this.mobile;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CARDNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.SUBSIDIARYNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTAMOUNT = 110;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DOCSID =
      "1110";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DISCOUNTAMOUNT =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.EMAIL = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CUSTOMERID =
      "";

    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.USERNAME =
      environment.userName;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.ORGID =
      environment.orgID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.TOKEN =
      environment.token;

    console.log(JSON.stringify(this.couponCodeEnquiryRequest));
    this.couponInquiryService
      .couponInquiry(this.couponCodeEnquiryRequest, environment.apiURL)
      .subscribe(
        (resp) => {
          if (resp.COUPONCODERESPONSE.STATUSINFO.STATUS === "Failure") {
            this.successMessage = resp.COUPONCODERESPONSE.STATUSINFO.MESSAGE;
            if (
              this.successMessage == "Error 100022: Member does not exists."
            ) {
              this.askRegister = true;
            }
            this.alertType = "danger";
            setTimeout(() => {
              this.successMessage = "";
              this.customerName = "";
            }, 3000);
          }
          this.display = `Loyalty balance is ${resp.COUPONCODERESPONSE.LOYALTYINFO.BALANCES[1].AMOUNT}  points and ${resp.COUPONCODERESPONSE.LOYALTYINFO.BALANCES[0].VALUECODE} ${resp.COUPONCODERESPONSE.LOYALTYINFO.BALANCES[0].AMOUNT} .<br>Cumulative loyalty points earned to date is ${resp.COUPONCODERESPONSE.LOYALTYINFO.LIFETIMEPOINTS} points.<br>Redemption currency available is ${resp.COUPONCODERESPONSE.LOYALTYINFO.BALANCES[0].VALUECODE} ${resp.COUPONCODERESPONSE.LOYALTYINFO.REDEEMABLEAMOUNT}.`;
          //this.display = resp.COUPONCODERESPONSE.LOYALTYINFO.DISPLAYTEMPLATE;
          this.customerCouponResponseArray =
            resp.COUPONCODERESPONSE.COUPONDISCOUNTINFO;
          this.customerName = resp.COUPONCODERESPONSE.LOYALTYINFO.CUSTOMERNAME;
          this.radeemableBalance =
            resp.COUPONCODERESPONSE.LOYALTYINFO.REDEEMABLEAMOUNT;
          this.isRespond = true;
          this.issuanceDesc = resp.COUPONCODERESPONSE.LOYALTYINFO.ISSUANCEDESC;
          this.redeemDesc = resp.COUPONCODERESPONSE.LOYALTYINFO.REDEEMDESC;
          console.log(JSON.stringify(resp));
          setTimeout(() => {
            this.close();
          }, 5000);
        },
        (err: any) => {
          this.isRespond = false;
          console.log("Failure Response");
        }
      );
  }
  submitFrom(
    firstName,
    lastName,
    mobile,
    emailAddress,
    dob,
    anniversary,
    info,
    decision,
    terms,
    promotions
  ) {
    this.firstName = firstName.value;
    this.lastName = lastName.value;
    this.mobileNumber = mobile.value;
    this.emailAddress = emailAddress.value;
    this.password = "";
    this.dob = dob.value;
    this.info = info.checked;
    this.decision = decision.checked;
    this.terms = terms.checked;
    this.promotions = promotions.checked;
    this.anniversary = anniversary.value;
    if (
      this.info &&
      this.terms &&
      this.promotions &&
      this.decision &&
      this.firstName &&
      this.lastName &&
      this.mobileNumber &&
      this.emailAddress &&
      !this.emailAddressValidError
    ) {
      this.sendOtp("Issue", "");
    } else {
      this.validate();
      this.successMessage = "Please fill out the required fields";
      this.alertType = "danger";
      setTimeout(() => {
        this.successMessage = "";
        this.customerName = "";
      }, 3000);
    }
  }
  validate() {
    if (!this.firstName) {
      this.firstNameError = true;
    } else {
      this.firstNameError = false;
    }
    if (!this.lastName) {
      this.lastNameError = true;
    } else {
      this.lastNameError = false;
    }
    if (!this.mobileNumber) {
      this.mobileError = true;
    } else {
      this.mobileError = false;
    }
    if (!this.emailAddress) {
      this.emailAddressError = true;
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (this.emailAddress.match(mailformat)) {
        this.emailAddressValidError = false;
      } else {
        this.emailAddressValidError = true;
      }
      this.emailAddressError = false;
    }
    if (!this.info) {
      this.infoError = true;
    } else {
      this.infoError = false;
    }
    if (!this.terms) {
      this.termsError = true;
    } else {
      this.termsError = false;
    }
    if (!this.promotions) {
      this.promotionsError = true;
    } else {
      this.promotionsError = false;
    }
    if (!this.decision) {
      this.decisionError = true;
    } else {
      this.decisionError = false;
    }
  }
  async sendOtp(type, otp) {
    this.otpReq.header.requestId = new Date().toISOString();
    this.otpReq.header.requestDate = new Date().toISOString();
    this.otpReq.customer.phone = "" + this.mobile;
    this.otpReq.header.requestType = type;
    this.otpReq.otpCode = otp;
    this.otpReq.customer.emailAddress = this.emailAddress;

    this.otpService.otp(this.otpReq, environment.apiURL).subscribe(
      (resp: any) => {
        if (resp.status.status === "Success") {
          if (type === "Issue") {
            this.OTOinput = true;
          } else {
            this.register();
          }
        } else {
          this.successMessage = resp.status.message;
          this.alertType = "danger";
          setTimeout(() => {
            this.successMessage = "";
            this.customerName = "";
          }, 3000);
        }
      },
      (err: any) => {
        this.isRespond = false;
        console.log("Failure Response");
      }
    );
  }
  termsClick() {
    let User = {
      userName: environment.userName,
      organizationId: environment.orgID,
      token: environment.token,
    };
    this.termsService.terms(User, environment.apiURL).subscribe(
      (resp: any) => {
        debugger;
        this.termsAndCond = resp.engTermsContent;
        this.open();
      },
      (err: any) => {
        this.isRespond = false;
        console.log("Failure Response");
      }
    );
  }
  register() {
    debugger;
    this.enrollReq.header.requestId = this.otpReq.header.requestId;
    this.enrollReq.header.requestDate = this.otpReq.header.requestDate;
    this.enrollReq.customer.firstName = this.firstName;
    this.enrollReq.customer.emailAddress = this.emailAddress;
    this.enrollReq.customer.lastName = this.lastName;
    this.enrollReq.customer.birthday = this.dob;
    this.enrollReq.customer.anniversary = this.anniversary;
    this.enrollReq.customer.phone = this.mobileNumber;
    this.enrollReq.customer.password = this.password;
    debugger;
    console.log(this.enrollReq);
    this.enrollService.enroll(this.enrollReq, environment.apiURL).subscribe(
      (resp: any) => {
        debugger;
        if (resp.status.status === "Success") {
          this.successMessage = resp.status.message;
          this.alertType = "success";
          setTimeout(() => {
            this.successMessage = "";
            this.customerName = "";
            this.askRegister = false;
            this.isFilled = true;
            this.isRespond = true;
            this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CARDNUMBER =
              resp.membership.cardNumber;
            this.couponInquiry();
          }, 3000);
        } else {
          this.successMessage = resp.status.message;
          this.alertType = "danger";
          setTimeout(() => {
            this.successMessage = "";
            this.customerName = "";

            this.askRegister = false;
            this.isFilled = true;
          }, 3000);
        }
      },
      (err: any) => {
        this.isRespond = false;
        console.log("Failure Response");
      }
    );
  }
}
