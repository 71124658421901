import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProcessReceiptRequest } from '../dto/processReceipt-request';
import { ProcessReceiptResponse } from '../dto/processReceipt.response';
@Injectable({
  providedIn: 'root'
})
export class ProcessReceiptService {
  constructor(private httpClient: HttpClient) {}

  processReceipt(processReceiptRequest: ProcessReceiptRequest, host) {
    return this.httpClient.post<ProcessReceiptResponse>(
      'https://' + host + '/subscriber/processReceipt.mqrm',
      processReceiptRequest
    );
  }
}
