import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LoginRequest } from '../dto/login-request';
import { LoginResponse } from '../dto/login-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private httpClient: HttpClient
    ) { }

  login(loginRequest: LoginRequest){
    return this.httpClient.post<LoginResponse>('https://'+environment.apiURL+'/subscriber/userLogin.mqrm', loginRequest);
  }
}
