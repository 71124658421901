import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CouponCodeEnquObj } from '../dto/coupon-inquiry-request';
import { CoupnCodeEnqResponse } from '../dto/coupon-inquiry-response';

@Injectable({
  providedIn: 'root'
})
export class CouponInquiryService {
  constructor(private httpClient: HttpClient) {}

  couponInquiry(couponCodeEnquObj: CouponCodeEnquObj, host) {
    return this.httpClient.post<CoupnCodeEnqResponse>(
      'https://' + host + '/subscriber/CouponCodeEnquiryRequestOPT.mqrm',
      couponCodeEnquObj
    );
  }
}
