<div class="wrapper">
  <form id="myFormScan">
    <div id="model-content">
      <!-- <div class="modal-header">
            <div class="row">
                <h1>OptCulture</h1>
            </div>
        </div> -->
      <div class="modal-body inheritOverflow pt-0 pb-0">
        <div class="row">
          <div class="brand pt-5">
            <div class="hello">
              <img src="./assets/images/Escapade-Logo.png" />
            </div>

            <fa-icon
              (click)="logOut()"
              class="angle"
              [icon]="faSignOut"
            ></fa-icon>
          </div>
          <div class="col-12"  *ngIf="!customerName">
            <div class="row">
              <div class="col-12 text-center">
                <h3 class="pb-4 h3">Dear Member !</h3>
            <h3 class="pb-4 h3">Scan Barcode from the <br>Escapade VIP mobile app</h3>
            
                    <img class="scanimg pb-2" src="./assets/images/scan.png" />
                    
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div>
              <div class="entermobile">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Membership Number"
                    aria-label="Mobile Number"
                    autofocus
                    #MobileNumber
                    aria-describedby="button-addon2"
                    (change)="onSearchChange($event.target.value)"
                  />
                  <!--div class="input-group-append">
                    <button
                      class="btn btn-outline-primary"
                      type="submit"
                      id="button-addon2"
                      (click)="enterMobile(MobileNumber)"
                    >
                      Enter
                    </button>
                  </div -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row user-grid">
                  <div class="col-12 text-center" *ngIf="customerName">
                    <h3 class="br-sub pb-4">
                      Welcome, Dear {{ customerName }} !<br>
                      <span  *ngIf="customerMembershipNumber">Membership Number: {{customerMembershipNumber}}
                      <br><br></span>
                      <img  *ngIf="greenMark" src="./assets/images/tick.png" width="100">
                      <img  *ngIf="redMark" src="./assets/images/cross.png" width="100">
                      <br><br>

                    Your Reward Balance : 
                      {{ loyaltyPoints }} Points
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </form>
  <form #myForm>
    <div id="model-content">
      <!-- <div class="modal-header">
          <div class="row">
              <h1>OptCulture</h1>
          </div>
      </div> -->
      <div class="modal-body pt-0 pb-0 no-bg">
        <ngb-alert
          #selfClosingAlert
          *ngIf="successMessage"
          [type]="alertType"
          (closed)="successMessage = ''"
          ><div [innerHtml]="successMessage"></div>
        </ngb-alert>
      </div>
    </div>
  </form>
</div>

<ng-template
  class="modal fade bd-example-modal-lg4"
  #content
  id="exampleModalCenter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
  let-modal
>
  <div style="text-align: center" class="modal-header">
    <h5>Please Enter the Verification Code Received on Email and/or SMS</h5>
    <!--button
      class="btn btn-primary mb-2"
      (click)="modalRef.close('Close click')"
      id="closebutton"
      #closebutton
      data-role="closeBtn"
      type="button"
    >
      Close
    </button-->
  </div>
  <div style="text-align: center" class="modal-body">
    <form
      id="otp-form"
      enctype="multipart/form-data"
      autocomplete="off"
      data-hasrequired="* Required Fields"
      novalidate="novalidate"
    >
      <label class="label" for="otp">
        <h5>Verification Code</h5>
      </label>
      <div class="control">
        <input
          name="otp"
          id="otp"
          title="Verification Code"
          #otpCode
          class="input-text otp"
          type="text"
          data-validate="{required:true}"
          aria-required="true"
        />
      </div>
      <input type="hidden" name="success" id="success" value="0" />
      <p class="text-danger pt-3" *ngIf="otpStatus">{{ otpStatus }}</p>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      (click)="loyaltyOTPAcknowledge(otpCode)"
      class="btn btn-primary"
    >
      Submit
    </button>
    <!-- <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button> -->
  </div>
</ng-template>

<ng-template #message let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ status }}</h4>
    <button
      #closetrigger
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p
      [ngClass]="{ 'text-danger': status === 'Failure' }"
      [innerHTML]="statusMessage"
    ></p>
  </div>

  <div class="modal-footer">
    <button mat-button class="btn btn-primary" (click)="navigateHome()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #couponRedemtionConfirm let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>Do you want to proceed with redemtion?</p>
  </div>

  <div class="modal-footer">
    <button
      mat-button
      class="btn btn-primary"
      (click)="cpRedemption(redeemCouponTbInput, message)"
    >
      OK
    </button>
    <button
      mat-button
      class="btn btn-primary"
      (click)="modalRedemtionConfirmation.close('Close click')"
    >
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #redemtionConfirm let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>Do you want to proceed with redemtion?</p>
  </div>

  <div class="modal-footer">
    <button
      mat-button
      class="btn btn-primary"
      (click)="redemption(redeemPointsTbInput, message)"
    >
      OK
    </button>
    <button
      mat-button
      class="btn btn-primary"
      (click)="modalCouponRedemtionConfirmation.close('Close click')"
    >
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #LogEntry let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>Do you want to proceed with Log Entry?</p>
  </div>

  <div class="modal-footer">
    <button
      mat-button
      class="btn btn-primary"
      (click)="cpRedemption(logEntry, message)"
    >
      OK
    </button>
    <button
      mat-button
      class="btn btn-primary"
      (click)="modalRedemtionConfirmation.close('Close click')"
    >
      Cancel
    </button>
  </div>
</ng-template>
