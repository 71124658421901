import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ContactRequest} from '../dto/add-update-request' 
import {ContactResponse} from '../dto/add-update-response' 


@Injectable({
  providedIn: 'root'
})
export class UpdateContactsService {

  constructor(private httpClient: HttpClient) {}

    updateContact(contactRequest: ContactRequest, host){
      return this.httpClient.post<ContactResponse>('https://' + host + '/subscriber/updateContactsOPT.mqrm', contactRequest);
    }
   
}
