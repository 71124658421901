import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { BarcodeFormat } from "@zxing/library";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { NgbAlert } from "@ng-bootstrap/ng-bootstrap";
import {
  NgbModalConfig,
  NgbModal,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { CouponCodeEnquObj } from "../dto/coupon-inquiry-request";
import { CoupnCodeEnqResponse } from "../dto/coupon-inquiry-response";
import { CouponInquiryService } from "../services/coupon-inquiry.service";
import { CouponDiscountInfo } from "../model/coupon-model/CouponDiscountInfo";
import { MAT_RADIO_DEFAULT_OPTIONS } from "@angular/material/radio";
import { LoyaltyOTPResponse } from "../dto/LoyaltyOTPResponse";
import { PointHiddenFieldParametersSuccess } from "../model/genesys-model/point-hidden-field-parameters";
import { PointHiddenFieldParametersFailed } from "../model/genesys-model/point-hidden-field-parameters";
import { CouponSuccessHiddenParamsSuccess } from "../model/genesys-model/coupon-hidden-field-parameters";
import { CouponSuccessHiddenParamsFailed } from "../model/genesys-model/coupon-hidden-field-parameters";
import { faL, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { ProcessReceiptRequest } from "../dto/processReceipt-request";
import { ProcessReceiptResponse } from "../dto/processReceipt.response";
import { ProcessReceiptService } from "../services/processReceipt-service";
import { StatusInfo } from "../model/coupon-model/StatusInfo";
import * as moment from "moment-timezone";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import { onBarcodeRead } from "physical-barcode-reader-observer";

@Component({
  selector: "app-loyalty-redemption",
  templateUrl: "./loyalty-redemption.component.html",
  styleUrls: ["./loyalty-redemption.component.css"],
  providers: [
    NgbModalConfig,
    NgbModal,
    NgbActiveModal,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: "accent" },
    },
  ],
})
export class LoyaltyRedemptionComponent implements OnInit {
  private _success = new Subject<string>();
  @ViewChild("closebutton")
  closebutton: any;
  @ViewChild("MobileNumber") MobileNumber: ElementRef;
  @ViewChild("content") content: ElementRef;
  @ViewChild("scanner", { static: false }) scanner: ZXingScannerComponent;
  @ViewChild("selfClosingAlert", { static: false }) selfClosingAlert: NgbAlert;
  processReceiptRequest: ProcessReceiptRequest;
  processReceiptResponse: ProcessReceiptResponse;
  couponCodeEnquiryRequest: CouponCodeEnquObj;
  couponCodeEnquiryResponse: CoupnCodeEnqResponse;
  pointHiddenFieldParametersSuccess: PointHiddenFieldParametersSuccess;
  pointHiddenFieldParametersFailed: PointHiddenFieldParametersFailed;
  couponSuccessHiddenParamsSuccess: CouponSuccessHiddenParamsSuccess;
  couponSuccessHiddenParamsFailed: CouponSuccessHiddenParamsFailed;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  selectedDevice: MediaDeviceInfo = null;
  desiredDevice: object;
  redMark: boolean = false;
  greenMark: boolean = false;
  route: ActivatedRoute;
  dateNow: Date;
  device: MediaDeviceInfo;
  isTxtFilled: boolean = true;
  hostURL: String;
  scannerEnabled: Boolean;
  isReset: String;
  faSignOut = faSignOut;
  localStorage: any;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;
  isOpenCam: boolean;
  alertType: string;
  successMessage: String;

  qrResultString: string;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  environment: String;

  eventType: String;
  billGUID: String;
  ISD: String;
  mobile: String;
  billValue: Number;
  IsEmployee: String;
  userName: String;
  couponsAvailed: Boolean;
  isPerc: Number;
  orgID: String;
  apply: {
    applyPoints: string;
    redeemPoints: string;
    Reason: string;
  };
  token: String;
  manualAdjustments: Boolean;
  popUp: Boolean;
  dformat: String;
  couponcode: String;
  OTP: String;
  couponName: String;
  couponType: String;
  ACCUMULATEDISCOUNT: String;
  basis: String;
  factor: String;
  barcode: string;
  entrylog: Boolean;
  maxRedeemValue: String;
  minPurchaseValue: String;
  enableRedeemApply: boolean;
  emailAddress: String;

  customerName: String;
  customerMembershipNumber: String;
  customerTotalRedeemableBalance: String;
  loyaltyPoints: String;
  customerCouponResponseArray: CouponDiscountInfo[];
  singleCustomerCouponResponseArray: CouponDiscountInfo[];

  modalRef: any;
  modalSuccess: any;
  modalRedemtionConfirmation: any;

  statusErrorCode: String;
  statusMessage: String;
  status: String;
  otpStatus: String;
  otpResponseVal: String;
  isCouponExist: Boolean;
  itemPrice: Number;

  redemptionResponse: any;
  redeemPointsTbInputValue: string;
  allowRedumtionOnPromoItem: any;
  faUserCircle = faUserCircle;

  constructor(
    private readonly _dialog: MatDialog,

    private router: Router,
    route: ActivatedRoute,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private couponInquiryService: CouponInquiryService,
    private processReceiptService: ProcessReceiptService,
    public activeModal: NgbActiveModal
  ) {
    //Loyalty Inquiry intialization
    this.route = route;
    config.backdrop = "static";
    config.keyboard = false;
    this.successMessage = "";
    this.eventType = "";
    this.billGUID = "";
    this.ISD = "";
    this.mobile = "";
    this.manualAdjustments = false;
    this.isPerc = 0;
    this.billValue = null;
    this.scannerEnabled = false;
    this.barcode = "";
    this.IsEmployee = "";
    this.entrylog = false;
    this.itemPrice = 0;
    this.userName = "";
    this.orgID = "";
    this.token = "";
    this.hostURL = environment.apiURL;
    this.popUp = false;
    this.couponcode = "";
    this.couponName = "";
    this.alertType = "";
    this.couponsAvailed = false;
    this.couponType = "";
    this.ACCUMULATEDISCOUNT = "";
    this.maxRedeemValue = "";
    this.minPurchaseValue = "";
    this.apply = {
      applyPoints: "",
      redeemPoints: "",
      Reason: "",
    };
    this.basis = "";
    this.factor = "";
    this.otpResponseVal = "";
    this.enableRedeemApply = false;
    this.isCouponExist = false;
    this.dateNow = new Date();
    this.allowRedumtionOnPromoItem = 0;
    this.OTP = "";
    this.dformat =
      [
        this.dateNow.getFullYear(),
        this.dateNow.getMonth() + 1,
        this.dateNow.getDate(),
      ].join("-") +
      " " +
      [
        this.dateNow.getHours(),
        this.dateNow.getMinutes(),
        this.dateNow.getSeconds(),
      ].join(":");

    this.customerName = "";
    this.customerMembershipNumber = "";
    this.customerTotalRedeemableBalance = "";

    this.redeemPointsTbInputValue = "";

    this.pointHiddenFieldParametersSuccess = {
      Allow_Point_Accrual: 0,
      Mobileno: "",
      OTP: "",
      Status: "",
      redeemedPoints: 0,
      redeemedValue: 0,
      ALLOW_POINT_REDEMPTION_BEFORE_BILL_SAVE: false,
      referenceNo: "",
    };
    this.pointHiddenFieldParametersFailed = {
      Errormessage: "",
      Mobileno: "",
      Status: "",
      referenceNo: "",
    };
    this.couponSuccessHiddenParamsSuccess = {
      ALLOW_POINT_ACCRUAL: 0,
      ALLOW_POINT_REDEMPTION: 0,
      ALLOW_REDEMPTION_ON_PROMO_ITEM: this.allowRedumtionOnPromoItem,
      BASIS: 0,
      COUPONCODE: "",
      FACTOR: 0,
      MAX_REDEEM_VALUE: 0,
      MIN_PURCHASE_VALUE: 0,
      OFFERCODE: "",
      REFERENCENO: "",
      STATUS: "",
    };
    this.couponSuccessHiddenParamsFailed = {
      Errormessage: "",
      Mobileno: "",
      Status: "",
      referenceNo: "",
    };

    //CouponInfo Intialization
    this.customerCouponResponseArray = [
      {
        COUPONNAME: "",
        COUPONCODE: "",
        COUPONTYPE: "",
        VALIDFROM: "",
        VALIDTO: "",
        DISCOUNTCRITERIA: "",
        EXCLUDEDISCOUNTEDITEMS: "",
        ACCUMULATEDISCOUNT: "",

        ELIGIBILITY: "",

        LOYALTYPOINTS: "",
        DISCOUNTINFO: [],

        LOYALTYVALUECODE: "",
        DESCRIPTION: "",
        APPLYATTRIBUTES: "",

        NUDGEPROMOCODE: "",
        NUDGEDESCRIPTION: "",
        APPLIEDCOUPONS: "",
      },
    ];

    this.couponCodeEnquiryRequest = {
      COUPONCODEENQREQ: {
        HEADERINFO: {
          REQUESTID: "",
          PCFLAG: "",
          STORENUMBER: "",
          SOURCETYPE: "",
          SUBSIDIARYNUMBER: "",
          RECEIPTNUMBER: "",
          ENTEREDAMOUNT: "", //for inquiry
          RECEIPTAMOUNT: "",
        },
        COUPONCODEINFO: {
          COUPONNAME: "",
          COUPONCODE: "",
          STORENUMBER: "",
          SUBSIDIARYNUMBER: "",
          RECEIPTNUMBER: "",
          ENTEREDAMOUNT: "", //for inquiry
          RECEIPTAMOUNT: 0, //for redemption
          CUSTOMERID: "",
          PHONE: "",
          EMAIL: "",
          DOCSID: "",
          DISCOUNTAMOUNT: "", //for smart promotion
          CARDNUMBER: "",
          APPLIEDCOUPONS: [],
        },
        PURCHASEDITEMS: [],
        USERDETAILS: {
          USERNAME: "",
          ORGID: "",
          TOKEN: "",
        },
      },
    };

    this.processReceiptRequest = {
      Head: {
        user: {
          userName: environment.userName,
          organizationId: environment.orgID,
          token: environment.token,
        },
        requestTimestamp: this.dformat,
        requestType: "New",
        enrollCustomer: "N",
        isLoyaltyCustomer: "Y",
        emailReceipt: "false",
        requestEndPoint: "/processReceipt.mqrm",
        printReceipt: "false",
        requestSource: "Prism",
        requestFormat: "JSON",
      },
      Body: {
        sid: JSON.stringify(Date.now()), //timestamp
        document_number: Date.now(), //sequence number start with 112001
        created_datetime: moment.utc(new Date()).tz(moment.tz.guess()).format(), //date & time
        total_discount_amt: 0,
        sale_total_tax_amt: 0,
        sale_total_amt: 70,
        sale_subtotal: 70,
        sold_qty: 1,
        return_qty: 0,
        taken_amt: 70,
        subsidiary_number: 1,
        store_number: 101,
        store_code: "101",
        bt_udf2: moment(moment.utc(new Date()).tz(moment.tz.guess()).format())
          .subtract({ hours: 12 })
          .format("dddd"),
        bt_cuid: "", //the Customer ID you get from Coupon Enquiry
        bt_last_name: "", // The Last Name you get
        bt_first_name: "", // The First Name you get
        bt_primary_phone_no: "", // The mobile number you get
        bt_email: "", // The email id you get
        tender_name: "Cash",
        items: [
          {
            item_pos: 1,
            quantity: 1,
            original_price: 0,
            price: 0,
            tax_percent: 0,
            tax_amount: 0,
            scan_upc: "50001",
            total_discount_percent: 0,
            total_discount_amount: 0,
            dcs_code: "Entry Fee",
            vendor_code: "Entry Fee",
            item_description1: "Entry Fee",
            item_description2: "",
            item_description3: "",
            item_description4: "",
            attribute: "",
            item_size: "",
            alu: "50001",
            total_discount_reason: "",
            discount_amt: 0,
            discount_perc: 0,
            discount_reason: "",
            invn_sbs_item_sid: "34567899876543211",
            invn_item_uid: "34567899876543211",
          },
        ],
        tenders: [
          {
            amount: 70,
            taken: 70,
            given: 0,
            currency_name: "Dollar",
            tender_name: "Cash",
            alphabetic_code: "USD",
          },
        ],
        transaction_total_tax_amt: 0,
        sale_tax1_amt: 0,
        transaction_tax1_amt: 0,
        transaction_total_amt: 70,
        sale_subtotal_with_tax: 70,
        transaction_subtotal_with_tax: 70,
        total_line_item: 1,
        total_item_count: 1,
        transaction_subtotal: 70,
        given_amt: 0,
        deposits: [],
        discounts: [],
        coupons: [],
        headers: {},
      },
      OptcultureDetails: {
        MembershipNumber: "8144621400000340", //Membership Number Scanned
        Email: "androidfeb24@mailinator.com", // The email id you get
        Phone: "6722100005", // The mobile phone you get
        CheckReward: "",
        LoyaltyRedeem: {
          DiscountAmount: "0",
        },
        LoyaltyRedeemReversal: "",
        Promotions: [
          {
            DiscountType: "Item",
            ItemCode: "34567899876543211",
            ItemDiscount: "70",
            QuantityDiscounted: "1.0",
            CouponCode: "entryfee",
            RewardRatio: "1-200",
          },
        ],
      },
    };

    this.processReceiptResponse = {
      RESPONSEINFO: {
        STATUS: {
          STATUS: "",
          MESSAGE: "",
          ERRORCODE: "",
        },
        DISPLAYTEMPLATE: "",
        CARDNUMBER: "",
      },
    };
  }

  ngOnInit(): void {
    this._success.subscribe((message) => (this.successMessage = message));
    this._success.pipe(debounceTime(3000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
    this.isOpenCam = false;
    this.dateNow = new Date();
    this.currentDevice = null;
    this.scannerEnabled = false;
    this.availableDevices;
    this.isReset = this.route.snapshot.queryParamMap.get("reset");
    this.localStorage = JSON.parse(localStorage.getItem("user"));
    if (!this.localStorage) {
      this.router.navigate(["/login"]);
    }
    if (this.isReset === "true") {
      this.doReset();
    }
    this.eventType = this.route.snapshot.queryParamMap.get("eventType");
    this.billGUID = this.route.snapshot.queryParamMap.get("billGUID");
    this.ISD = this.route.snapshot.queryParamMap.get("ISD");
    this.mobile = this.route.snapshot.queryParamMap.get("mobile");
    this.billValue = Number(this.route.snapshot.queryParamMap.get("billValue"));
    this.customerMembershipNumber =
      this.route.snapshot.queryParamMap.get("membership");
    if (!this.route.snapshot.queryParamMap.get("membership")) {
      this.customerMembershipNumber = "";
    }
    this.userName = environment.userName;
    this.orgID = environment.orgID;
    this.token = environment.token;
    this.hostURL = environment.apiURL;
    this.localStorage = JSON.parse(localStorage.getItem("user"));
    if (this.localStorage) {
      //this.couponInquiry();
    } else {
      this.router.navigate(["/login"]);
    }
    if (this.eventType == "pointsRedemption") {
      this.enableRedeemApply = true;
    }
    onBarcodeRead().subscribe((result) => {
      //const barcode = result.barcode;
      this.barcode = result.barcode;
      // const type = result.type;
      //  const lastTarget = result.target;
      this.qrResultString = this.barcode;
      if (this.qrResultString.length === 16) {
        this.mobile = "";
        this.customerMembershipNumber = this.qrResultString;
        this.couponInquiry();
      }
    });
  }

  clearResult(): void {
    this.qrResultString = null;
  }

  /*  interval;
  
    startTime() {
      this.interval = setTimeout(() => {
        alert("Alert actiItem sync Success")
      }, 1000);
    }
  */
  doReset() {
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.HEADERINFO.REQUESTID =
      this.dateNow.toString();
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.COUPONCODE =
      "ALLCODES";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.STORENUMBER =
      "101";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.PHONE = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTNUMBER =
      "20220225181900";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CARDNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.SUBSIDIARYNUMBER =
      "1";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTAMOUNT = 435;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DOCSID = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DISCOUNTAMOUNT =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.PURCHASEDITEMS = [];
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.EMAIL = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CUSTOMERID =
      "";

    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.USERNAME =
      environment.userName;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.ORGID =
      environment.orgID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.TOKEN =
      environment.token;
    this.couponInquiryService
      .couponInquiry(this.couponCodeEnquiryRequest, this.hostURL)
      .subscribe(
        (resp) => {
          this.pushItems(resp);

          this.alertType = "success";
          this.successMessage = "Item sync Success";
          this.MobileNumber.nativeElement.focus();
          setTimeout(() => {
            //<<<---using ()=> syntax
            this.successMessage = "";
          }, 3000);
        },
        (err: any) => {
          this.alertType = "danger";
          this.successMessage = "Failure Response";
          this.MobileNumber.nativeElement.focus();
          setTimeout(() => {
            //<<<---using ()=> syntax
            this.successMessage = "";
          }, 3000);
        }
      );
  }

  pushItems(coupons) {
    var items = [];
    var getItems = coupons.COUPONCODERESPONSE.COUPONDISCOUNTINFO;
    for (var i = 0; i < getItems.length; i++) {
      if (typeof getItems[i].DISCOUNTINFO !== "undefined") {
        for (var j = 0; j < getItems[i].DISCOUNTINFO.length; j++) {
          if (
            typeof getItems[i].DISCOUNTINFO[j].ITEMCODEINFO[0] !== "undefined"
          ) {
            items.push(getItems[i].DISCOUNTINFO[j].ITEMCODEINFO[0]);
            getItems[i].DISCOUNTINFO[j].ITEMCODEINFO[0].QUANTITY = "1";
          }
        }
      }
    }
    const uniqueArray = items.filter((item, index) => {
      const _items = JSON.stringify(item);
      return (
        index ===
        items.findIndex((obj) => {
          return JSON.stringify(obj) === _items;
        })
      );
    });
    localStorage.setItem("items", JSON.stringify(uniqueArray));
  }

  // onCamerasFound(devices: MediaDeviceInfo[]) {
  //   this.availableDevices = devices;
  //   this.hasDevices = Boolean(devices && devices.length);

  //   for (let index = 0; index < this.availableDevices.length; index++) {
  //     if (
  //       this.availableDevices[index].label.toLowerCase().includes("hp") ||
  //       this.availableDevices[index].label.toLowerCase().includes("rear") ||
  //       this.availableDevices[index].label.toLowerCase().includes("back")
  //     ) {
  //       const device = this.availableDevices.find(
  //         (x) => x.deviceId === this.availableDevices[index].deviceId
  //       );
  //       this.currentDevice = device || null;
  //       this.scanner.device = device || null;
  //       this.selectedDevice = device || null;
  //       break;
  //     }
  //   }
  // }

  // ondeviceChange(currentDevice) {
  //   this.currentDevice = currentDevice || null;
  //   this.scanner.device = currentDevice || null;
  //   this.selectedDevice = currentDevice || null;
  // }

  onCodeResult(resultString: string) {
    this.mobile = "";
    this.customerMembershipNumber = resultString;
    this.greenMark = false;
    this.redMark = false;
    this.couponInquiry();
  }

  onSearchChange(MobileNumber: string): void {
    this.qrResultString = MobileNumber;
    if (this.qrResultString.length === 16) {
      this.mobile = "";
      this.customerMembershipNumber = this.qrResultString;
      this.greenMark = false;
      this.redMark = false;
      if (this.isTxtFilled) {
        this.isTxtFilled = false;
        this.couponInquiry();
      }
    }
  }

  enterMobile(MobileNumber: any) {
    this.qrResultString = MobileNumber.value;
    if (this.qrResultString.length > 11) {
      this.mobile = "";
      this.customerMembershipNumber = this.qrResultString;
      this.greenMark = false;
      this.redMark = false;
      this.couponInquiry();
    } else {
      this.mobile = this.qrResultString;
      this.customerMembershipNumber = "";
      this.greenMark = false;
      this.redMark = false;
      this.couponInquiry();
    }
  }

  // onDeviceSelectChange(selected: string) {
  //   this.scannerEnabled = true;
  //   const device = this.availableDevices.find((x) => x.deviceId === selected);
  //   this.scanner.device = device || null;
  // }

  // onHasPermission(has: boolean) {
  //   this.hasPermission = has;
  // }

  // onTorchCompatible(isCompatible: boolean): void {
  //   this.torchAvailable$.next(isCompatible || false);
  // }

  // toggleTorch(): void {
  //   this.torchEnabled = !this.torchEnabled;
  // }

  // toggleTryHarder(): void {
  //   this.tryHarder = !this.tryHarder;
  // }
  // openCam() {
  //   this.scanner.scannerEnabled = true;
  //   this.onDeviceSelectChange(this.selectedDevice.deviceId);
  // }
  logOut() {
    localStorage.removeItem("user");
    localStorage.removeItem("items");
    this.router.navigate(["/login"]);
  }
  /*   -------------                        */

  couponInquiry() {
    this.isTxtFilled = false;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.HEADERINFO.REQUESTID =
      this.dateNow.toString();
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.COUPONCODE =
      "ALL";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.STORENUMBER =
      "101";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.PHONE =
      this.mobile;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTNUMBER =
      "20220225181900";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CARDNUMBER =
      this.customerMembershipNumber;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.SUBSIDIARYNUMBER =
      "1";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTAMOUNT = 435;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DOCSID = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DISCOUNTAMOUNT =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.PURCHASEDITEMS = JSON.parse(
      localStorage.getItem("items")
    );
    /*this.couponCodeEnquiryRequest.COUPONCODEENQREQ.PURCHASEDITEMS =
     [
      {
          "ITEMPRICE": 15,
          "ITEMCODE": "12345667899876543",
          "QUANTITY": "1",
          "ITEMDISCOUNT": "0"
      },
      {
          "ITEMPRICE": 70,
          "ITEMCODE": "34567899876543211",
          "QUANTITY": "1",
          "ITEMDISCOUNT": "0"
      },
      {
          "ITEMPRICE": 100,
          "ITEMCODE": "23456789987654321",
          "QUANTITY": "1",
          "ITEMDISCOUNT": "0"
      },
      {
          "ITEMPRICE": 250,
          "ITEMCODE": "45678998765432123",
          "QUANTITY": "1",
          "ITEMDISCOUNT": "0"
      }
  ];*/
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.EMAIL = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CUSTOMERID =
      "";

    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.USERNAME =
      environment.userName;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.ORGID =
      environment.orgID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.TOKEN =
      environment.token;
    this.couponInquiryService
      .couponInquiry(this.couponCodeEnquiryRequest, this.hostURL)
      .subscribe(
        (resp) => {
          if (resp.COUPONCODERESPONSE.STATUSINFO.STATUS === "Failure") {
            this.successMessage = resp.COUPONCODERESPONSE.STATUSINFO.MESSAGE;
            this.alertType = "danger";
            setTimeout(() => {
              this.successMessage = "";
              this.customerName = "";
              this.isTxtFilled = true;
              this.MobileNumber.nativeElement.value = "";
            }, 3000);
          }
          this.scannerEnabled = false;

          this.setCouponValues(resp);
          this.loyaltyInquiry(resp);
          this.MobileNumber.nativeElement.focus();
          this.couponRedemption();
          this.MobileNumber.nativeElement.value = "";
        },
        (err: any) => {
          this.alertType = "danger";
          this.MobileNumber.nativeElement.value = "";
          this.successMessage = "Failure Response";
          this.MobileNumber.nativeElement.focus();

          this.customerName = "";
          setTimeout(() => {
            //<<<---using ()=> syntax
            this.successMessage = "";
            this.isTxtFilled = false;
            this.MobileNumber.nativeElement.value = "";
          }, 2000);
          this.scannerEnabled = false;
        }
      );
  }

  inputCouponInquiry() {
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.HEADERINFO.REQUESTID =
      this.dateNow.toString();
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.COUPONCODE =
      this.couponcode;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.STORENUMBER =
      "1";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.PHONE =
      this.mobile;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CARDNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.SUBSIDIARYNUMBER =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.RECEIPTAMOUNT =
      this.billValue;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DOCSID =
      this.billGUID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.DISCOUNTAMOUNT =
      "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.EMAIL = "";
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.COUPONCODEINFO.CUSTOMERID =
      "";

    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.USERNAME =
      environment.userName;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.ORGID =
      environment.orgID;
    this.couponCodeEnquiryRequest.COUPONCODEENQREQ.USERDETAILS.TOKEN =
      environment.token;
  }

  loyaltyInquiry(resp) {
    this.customerName = resp.COUPONCODERESPONSE.LOYALTYINFO.CUSTOMERNAME;
    this.customerMembershipNumber =
      resp.COUPONCODERESPONSE.LOYALTYINFO.CARDNUMBER;
    this.customerTotalRedeemableBalance =
      resp.COUPONCODERESPONSE.LOYALTYINFO.REDEEMABLEAMOUNT;
    this.loyaltyPoints = resp.COUPONCODERESPONSE.LOYALTYINFO.BALANCES[1].AMOUNT;
    this.mobile = resp.COUPONCODERESPONSE.LOYALTYINFO.PHONE;
    this.emailAddress = resp.COUPONCODERESPONSE.LOYALTYINFO.EMAIL;
  }

  setCouponValues(couponCodeEnquiryResponse: CoupnCodeEnqResponse) {
    this.customerCouponResponseArray =
      couponCodeEnquiryResponse.COUPONCODERESPONSE.COUPONDISCOUNTINFO;
    if (typeof this.customerCouponResponseArray[0] !== "undefined") {
      this.couponsAvailed = true;
    }
  }

  setOtpResposne(loyaltyOTPResponse: LoyaltyOTPResponse) {
    this.statusErrorCode = loyaltyOTPResponse.status.errorCode;
    this.statusMessage = loyaltyOTPResponse.status.message;
    this.status = loyaltyOTPResponse.status.status;
  }
  setLoyaltyRedemptionResponse(
    errorCode: String,
    message: String,
    status: String
  ) {
    this.statusErrorCode = errorCode;
    this.statusMessage = message;
    this.status = status;
  }

  cpRedemption(redeemCouponTbInput: any, message: any) {
    //   this.couponRedemption(redeemCouponTbInput, message);
  }
  cpAdjustments(redeemCouponTbInput: any, message: any) {
    this.manualAdjustments = true;
    if (this.loyaltyPoints === "0") {
      this.alertType = "danger";
      this.successMessage = "No balance avaialable";
      this.MobileNumber.nativeElement.focus();
      setTimeout(() => {
        //<<<---using ()=> syntax
        this.successMessage = "";
      }, 3000);
      return false;
    }
    // this.couponRedemption(redeemCouponTbInput, message);
  }

  entryOnly(redeemCouponTbInput: any, message: any) {
    debugger;
    //  this.couponRedemption(redeemCouponTbInput, message);
  }

  couponRedemption() {
    this.entrylog = true;
    this.processReceiptRequest = {
      Head: {
        user: {
          userName: environment.userName,
          organizationId: environment.orgID,
          token: environment.token,
        },
        requestTimestamp: this.dformat,
        requestType: "New",
        enrollCustomer: "N",
        isLoyaltyCustomer: "Y",
        emailReceipt: "false",
        requestEndPoint: "/processReceipt.mqrm",
        printReceipt: "false",
        requestSource: "Prism",
        requestFormat: "JSON",
      },
      Body: {
        sid: JSON.stringify(Date.now()), //timestamp
        document_number: Date.now(), //sequence number start with 112001
        created_datetime: moment.utc(new Date()).tz(moment.tz.guess()).format(), //date & time
        total_discount_amt: null,
        sale_total_tax_amt: 0,
        sale_total_amt: null,
        sale_subtotal: null,
        sold_qty: 1,
        return_qty: 0,
        taken_amt: null,
        subsidiary_number: 1,
        store_number: 101,
        store_code: "101",
        bt_udf2: moment(moment.utc(new Date()).tz(moment.tz.guess()).format())
          .subtract({ hours: 12 })
          .format("dddd"),
        bt_cuid: this.customerMembershipNumber, //the Customer ID you get from Coupon Enquiry
        bt_last_name: this.customerName.split(" ").slice(1).join(" "), // The Last Name you get
        bt_first_name: this.customerName.split(" ").slice(0, -1).join(" "), // The First Name you get
        bt_primary_phone_no: this.mobile, // The mobile number you get
        bt_email: this.emailAddress, // The email id you get
        tender_name: "Cash",
        items: [
          {
            item_pos: 1,
            quantity: 1,
            original_price: 0,
            price: 0,
            tax_percent: 0,
            tax_amount: 0,
            scan_upc: "50001",
            total_discount_percent: 0,
            total_discount_amount: 0,
            dcs_code: "Entry Fee",
            vendor_code: "Entry Fee",
            item_description1: "Entry Fee",
            item_description2: "",
            item_description3: "",
            item_description4: "",
            attribute: "",
            item_size: "",
            alu: "50001",
            total_discount_reason: "",
            discount_amt: 0,
            discount_perc: 0,
            discount_reason: "",
            invn_sbs_item_sid: "34567899876543211",
            invn_item_uid: "34567899876543211",
          },
        ],
        tenders: [
          {
            amount: null,
            taken: null,
            given: 0,
            currency_name: "Dollar",
            tender_name: "Cash",
            alphabetic_code: "USD",
          },
        ],
        transaction_total_tax_amt: 0,
        sale_tax1_amt: 0,
        transaction_tax1_amt: 0,
        transaction_total_amt: null,
        sale_subtotal_with_tax: null,
        transaction_subtotal_with_tax: null,
        total_line_item: 1,
        total_item_count: 1,
        transaction_subtotal: null,
        given_amt: 0,
        deposits: [],
        discounts: [],
        coupons: [],
        headers: {},
      },
      OptcultureDetails: {
        MembershipNumber: this.customerMembershipNumber, //Membership Number Scanned
        Email: this.emailAddress, // The email id you get
        Phone: this.mobile, // The mobile phone you get
        CheckReward: "Membership code already scanned",
        LoyaltyRedeem: {
          DiscountAmount: "0",
        },
        LoyaltyRedeemReversal: "",
        Promotions: null,
      },
    };
    for (var val of this.customerCouponResponseArray) {
      if (val.COUPONCODE == "entryfee") {
        for (var item of this.couponCodeEnquiryRequest.COUPONCODEENQREQ
          .PURCHASEDITEMS) {
          if (val.DISCOUNTINFO[0].ITEMCODEINFO[0].ITEMCODE == item.ITEMCODE) {
            this.itemPrice = item.ITEMPRICE;
          }
        }
        this.processReceiptRequest.Body.items = [
          {
            item_pos: 1,
            quantity: 1,
            original_price: Number(this.itemPrice),
            price: Number(this.itemPrice),
            tax_percent: 0,
            tax_amount: 0,
            scan_upc: "50001",
            total_discount_percent: 0,
            total_discount_amount: 0,
            dcs_code:
              val.DISCOUNTINFO[0].ITEMCODEINFO[0].ITEMATTRIBUTE[0]
                .ATTRIBUTECODE,
            vendor_code:
              val.DISCOUNTINFO[0].ITEMCODEINFO[0].ITEMATTRIBUTE[0]
                .ATTRIBUTECODE,
            item_description1:
              val.DISCOUNTINFO[0].ITEMCODEINFO[0].ITEMATTRIBUTE[0]
                .ATTRIBUTECODE,
            item_description2: "",
            item_description3: "",
            item_description4: "",
            attribute: "",
            item_size: "",
            alu: "50001",
            total_discount_reason: "",
            discount_amt: this.isPerc,
            discount_perc: 0,
            discount_reason: "",
            invn_sbs_item_sid: val.DISCOUNTINFO[0].ITEMCODEINFO[0].ITEMCODE,
            invn_item_uid: val.DISCOUNTINFO[0].ITEMCODEINFO[0].ITEMCODE,
          },
        ];
      }
    }

    this.processReceiptService
      .processReceipt(this.processReceiptRequest, this.hostURL)
      .subscribe(
        (resp) => {
          this.processReceiptResponse = resp;
          if (
            resp != null &&
            this.processReceiptResponse.RESPONSEINFO.STATUS.STATUS == "Failure"
          ) {
            if (
              this.processReceiptResponse.RESPONSEINFO.STATUS.ERRORCODE ==
              "300004"
            ) {
              this.couponSuccess(resp);
            } else {
              this.couponErr(resp);
            }
          } else {
            this.couponSuccess(resp);
          }
          this.apply.redeemPoints = "";
        },
        (err: any) => {
          this.couponErr(err);
        }
      );
  }

  couponSuccess(resp) {
    this.popUp = true;
    let message = "Success";
    this.alertType = "success";
    if (resp.RESPONSEINFO.CHECKREWARD) {
      this.successMessage = resp.RESPONSEINFO.CHECKREWARD;
      this.alertType = "danger";
      this.redMark = true;
      this.greenMark = false;
    } else {
      if (
        resp != null &&
        typeof resp.RESPONSEINFO.DISPLAYTEMPLATE !== "undefined"
      ) {
        this.greenMark = true;
        this.redMark = false;
        //var re = /\\n/gi;
        //var str = resp.RESPONSEINFO.DISPLAYTEMPLATE;
        //var newstr = str.replace(re, "<br>");
        //message = newstr;
        let newstr = resp.RESPONSEINFO.DISPLAYTEMPLATE.split("\\n");
        message = newstr[0];
      }
      if (this.entrylog) {
        this.successMessage = message;
      } else {
        if (resp.RESPONSEINFO.DISPLAYTEMPLATE === "") {
          message = "Success";
          this.successMessage = message;
        }
        this.successMessage = message;
      }
    }

    this.manualAdjustments = false;

    this.MobileNumber.nativeElement.value = "";
    this.MobileNumber.nativeElement.focus();

    setTimeout(() => {
      //<<<---using ()=> syntax
      this.successMessage = "";
      this.customerName = "";
      this.isTxtFilled = true;
      this.MobileNumber.nativeElement.value = "";
    }, 5000);
  }
  couponErr(resp) {
    this.popUp = true;

    this.alertType = "danger";
    let errorMessage = "error";
    if (resp.statusText) {
      errorMessage = resp.statusText;
    } else {
      errorMessage = resp.RESPONSEINFO.STATUS.MESSAGE;
    }
    this.successMessage = errorMessage;

    this.manualAdjustments = false;

    this.redMark = true;
    this.greenMark = false;

    this.MobileNumber.nativeElement.value = "";
    this.MobileNumber.nativeElement.focus();
    setTimeout(() => {
      //<<<---using ()=> syntax
      this.successMessage = "";
      this.isTxtFilled = true;
      this.MobileNumber.nativeElement.value = "";
    }, 5000);
  }

  couponRedemptionDialog(couponRedemtionConfirm: any) {
    this.entrylog = false;
  }

  submitHiddenRequest(
    message: String,
    eventType: String,
    errorCode: String,
    points: String
  ) {
    this.couponSubmit(errorCode, message);
  }

  couponSubmit(errorCode: String, message: String) {
    if (this.ACCUMULATEDISCOUNT.toLocaleUpperCase() === "TRUE") {
      this.allowRedumtionOnPromoItem = 1;
    } else {
      this.allowRedumtionOnPromoItem = 0;
    }
    if (errorCode == "0") {
      this.redemptionResponse = {
        ALLOW_POINT_ACCRUAL: 1,
        ALLOW_POINT_REDEMPTION: 0,
        ALLOW_REDEMPTION_ON_PROMO_ITEM: this.allowRedumtionOnPromoItem,
        ALLOW_COUPON_REDEMPTION_BEFORE_BILL_SAVE: false,
        BASIS: this.basis,
        COUPONCODE: this.couponcode,
        FACTOR: this.factor,
        MAX_REDEEM_VALUE: null,
        MIN_PURCHASE_VALUE: this.minPurchaseValue,
        OFFERCODE: this.couponName,
        REFERENCENO: this.billGUID,
        MOBILENO: this.mobile,
        OTP: this.OTP,
        STATUS: "Success",
      };
    } else {
      this.redemptionResponse = {
        Errormessage: message,
        Mobileno: this.mobile,
        Status: "Failed",
        referenceNo: this.billGUID,
      };
    }
    this.redemptionResponse = JSON.stringify(this.redemptionResponse);
  }

  logEntry(e) {
    this.entrylog = true;
    //this.couponRedemptionDialog(couponRedemtionConfirm: any);
  }

  radioChangeForCoupon(event: any) {
    this.redeemPointsTbInputValue = event.target.value;
  }
  radioSelection(event: any) {
    //if(event.target.value === ''){}
  }
  navigateHome() {
    this.modalSuccess.close();
    this.router.navigate([""]);
  }
  navigateScan() {
    this.router.navigate([""]);
  }
}
