import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class OtpService {
  constructor(private httpClient: HttpClient) {}

  otp(otpObject: any, host) {
    return this.httpClient.post(
      "https://" + host + "/subscriber/OCOTPService.mqrm",
      otpObject
    );
  }
}
