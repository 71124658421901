import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoyaltyTransactionHistoryRequest} from '../dto/loyalty-transaction-history-request'
import {LoyaltyTransactionHistoryResponse} from '../dto/loyalty-transaction-history-response'


@Injectable({
  providedIn: 'root'
})
export class TransactionHistoryService {

  constructor(private httpClient: HttpClient) { }

  transactionHistory(loyaltyTransactionHistoryRequest: LoyaltyTransactionHistoryRequest, host){
    return this.httpClient.post<LoyaltyTransactionHistoryResponse>('https://' + host + '/subscriber/loyaltyTransactionHistory.mqrm', loyaltyTransactionHistoryRequest);
  }

}
