import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class TermsService {
  constructor(private httpClient: HttpClient) {}

  terms(termsObject: any, host) {
    return this.httpClient.post(
      "https://" + host + "/subscriber/faqAndTerms.mqrm",
      termsObject
    );
  }
}
