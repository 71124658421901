import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ImportRequest} from '../dto/import-request' 
import {ImportResponse} from '../dto/import-response' 


@Injectable({
  providedIn: 'root'
})
export class ImportContactsService {

  constructor(private httpClient: HttpClient) { }

  importContact(importRequest: ImportRequest, host){
    return this.httpClient.post<ImportResponse>('https://' + host + '/subscriber/importContact.mqrm', importRequest);
  }
}
