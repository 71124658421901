import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class EnrollService {
  constructor(private httpClient: HttpClient) {}

  enroll(enrollObject: any, host) {
    return this.httpClient.post(
      "https://" + host + "/subscriber/OCLoyaltyEnrollment.mqrm",
      enrollObject
    );
  }
}
