<div class="background">
<aside role="dialog" class="modal-popup pop-out
               modal-slide
               _inner-scroll" id="otp-dialog" aria-labelledby="modal-title-0" aria-describedby="modal-content-0" data-role="modal" data-type="popup" tabindex="0">
    <div data-role="focusable-start " tabindex="0"></div>
    <div class="modal-inner-wrap" data-role="focusable-scope">
        <header class="modal-header">
            
            <h1 id="modal-title-0" class="modal-title" data-role="title">
                
                    Please Enter the Verification Code Received on Email and/or SMS
                
            </h1>       
            <button mat-dialog-close class="btn btn-primary mb-2" data-role="closeBtn" type="button">
                <span>Close</span>
            </button>
        </header>
        <div id="modal-content-0" class="modal-content" data-role="content"><div id="myModel" >
            <p class="message success" id="modelSMsg" style="display:none"></p>
            <p class="message error" id="modelEMsg" style="display:none"></p>
            <form id="otp-form" enctype="multipart/form-data" autocomplete="off" data-hasrequired="* Required Fields" novalidate="novalidate">
                <div class="field otp required">
                    <label class="label" for="otp">
                        <span>Verification Code</span>
                    </label>
                    <div class="control">
                        <input name="otp" id="otp" title="Verification Code" #otpCode class="input-text otp" type="text" data-validate="{required:true}" aria-required="true">
                    </div>
                </div>
                <input type="hidden" name="success" id="success" value="0">
            </form>
            <div id="dvresendotp" style="display: none">
                You can request another verification code in  <span id="otpCount"></span> secs            </div>
            <!-- <a id="resendLink" class="resendotp" href="http://192.168.0.106/magento235p2/checkout/#" style="display: none">RESEND CODE</a> -->
            <span id="ajax_loader_model" style="display:none">
                <img src="./Checkout_files/loading.gif" style="height: 30px;width: 30px">
            </span>
        </div></div>
        
        <footer class="modal-footer">
            
            <button class="btn btn-primary mb-2" (click)="loyaltyOTP(otpCode)" type="button" data-role="action"><span>Submit</span></button>
            
        </footer>
        
    </div>
    <div data-role="focusable-end" tabindex="0"></div>
</aside>
</div>

