import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoyaltyInquiryRequest} from '../dto/loyalty-inquiry-request'
import {LoyaltyInquiryResponse} from '../dto/loyalty-inquiry-response'

@Injectable({
  providedIn: 'root'
})
export class LoyaltyInquiryService {

  constructor(private httpClient: HttpClient) { }
  loyaltyInquiry(loyaltyInquiryRequest: LoyaltyInquiryRequest, host){
    return this.httpClient.post<LoyaltyInquiryResponse>('https://' + host + '/subscriber/OCLoyaltyInquiry.mqrm', loyaltyInquiryRequest);
  }
}
