<!DOCTYPE html>
<html lang="en" dir="ltr">

<head>
  <meta charset="utf-8">
  <title></title>
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
</head>

<body>
<section class="top-black-bar-section">
  <div class="top-black-bar">
    <i class="fa fa-close" style="font-size:24px;color:white"></i>
    <p>Customer Profile/{{firstName}} {{lastName}}</p>
  </div>
</section>
<section class="second-row-section">
  <div class="second-row">
    <div class="qq row">
      <div class="profile-column col-lg-4">
        <div class="AJ-profile">
        {{firstName.charAt(0)}}{{lastName.charAt(0)}}
        </div>
        <p class="profile-name">{{firstName}} {{lastName}}</p>
        <p class="profile-no">{{phoneNumber}}</p>
        <button type="button" data-toggle="modal"  data-target="#update-modal"  (click)="open(content)"  id=btn-update class="btn btn-danger profile-update-button">Update</button>
        <img src="/assets/images/Group23.png" alt="" class="powered">
      </div>
      <div class="contact-column col-lg-8">
        <div class="rr row">
          <div class="contact-details col-lg-4">
            <p class="contact-heading">Contact Details</p>
            <p class="contact-phn"><img src="/assets/images/Icon_awesome-phone-alt.svg" alt="">{{phoneNumber}}</p>
            <p class="contact-mail"><img src="/assets/images/Icon_feather-mail.svg" alt="">{{emailAddress}}</p>
            <p class="contact-aniversary">Birth & Anniversary Details</p>
            <p class="contact-date"><img src="/assets/images/XMLID_1506_.svg" alt="">{{birthDay | date}}</p>
            <p class="contact-anniversary-date"><img src="/assets/images/anniversary.svg" alt="">{{anniversary | date}}</p>
            <p class="contact-more" data-toggle="modal" (click)="moreInfo(info)" data-target=".bd-example-modal-lg">More Information <img src="/assets/images/Icon_ionic-ios-arrow-round-forward.svg" alt=""></p>
          </div>
          <div [style.background-image]="'url(/assets/images/' + tierImg + ')'" class="contact-teir-div col 2 {{tierLevel}}">
            <div class="contact-teir-div-content">
              <div class="aa" style="">
                <p class="ab" style="">Tier</p>
                <p class="ac" style="" *ngIf="tierName">{{tierName}}</p>
                <p class="ac" style="" *ngIf="!tierName">--</p>
              </div>
              <div class="bb" style="">
                <span class="ab"  style="">STATUS </span><span class="ab">EXPIRY</span>
              </div>
              <div class="bc" style="">
                <span class="ae" style="">{{statusExpiry}}</span>
              </div>
              <div class="bd" style="">
                <p class="ab">LIFETIME PURCHASE VALUE</p>
                <p class="ae">{{lifeTimePurchaseVal}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="third-section">
  <div class="third-row">
    <div class="tt row">
      <div class=" down-first col-lg-6">
        <div class="yy row">
          <div class=" green-border col-lg-6">
            <p class="money-green">₹{{loyaltyAmount}}</p>
            <p class="money-point"> <span style="padding-right:5px">{{loyaltyPoints}} Points</span> <!--span style="padding-left:5px">N/A</span-->  </p>
            <div class="money-current">
              <p>CURRENT BALANCE</p>
            </div>

          </div>
          <div class=" red-border col-lg-6">
            <p class="money-red">₹{{loyaltyAmountExpire}}</p>
            <p class="money-point"> <span style="padding-right:5px">{{loyaltyPointsExpire}} Points</span> <!--span style="padding-left:5px">N/A</span--> </p>
            <div class="money-current">
              <p>EXPIRING THIS MONTH</p>
            </div>

          </div>
        </div>

        <div class="">
          <p class="redeemable">Redeemable currency available : ₹{{totalRedeemableCurrency}}</p>
        </div>
        <div class="gradient" *ngIf="nextTierMilestone" >
          <p>You are {{nextTierMilestone}} away from {{nextTierName}}</p>
          <div class="level-total">
            <div class="level-outer">
              <div class="level-inner"  [style.width]="this.percentBar + '%'" >
              </div>
            </div>
            <div class="silver-name">
              {{tierName}}
            </div>
            <div class="gold-name">
              {{nextTierName}}
            </div>
          </div>

        </div>
          <div class="under-level">
            <p class="Loyalty">
              Loyalty Program rules
            </p>
            <!-- <p class="see-teir" data-toggle="modal" (click)="openTier(tier)" data-target=".bd-example-modal-lg4">
              See all tiers <img src="/assets/images/Icon_ionic-ios-arrow-round-forward.svg" alt="">
            </p> -->
            <p class="earn">{{issuanceDesc}}</p>
            <p class="under-earn">{{redeemDesc}}</p>
            <!-- <p class="bonuses-available" data-toggle="modal" data-target=".bd-example-modal-lg1">Bonuses Available <img src="/assets/images/Icon_ionic-ios-arrow-round-forward.svg" alt=""></p> -->
          </div>
            <div class="transaction-button">
              <button type="button" class="btn btn-outline-primary" data-toggle="modal" (click)="openTransactionHistory(transactionHistory)" data-target=".bd-example-modal-lg2">Transaction History</button>
              <a href="https://{{hostURL}}" target="_blank" class="btn btn-danger">Manage</a>
            </div>
      </div>
      <div class=" vouchers col">
        <div class="discounts">
          <p>AVAILABLE DISCOUNTS</p>
        </div>
        <div class="voucher-img-margin">
        <div *ngFor="let data of customerCouponResponseArray" class="vouchers-img">
          <p class="vouchers-img-heading">{{data.COUPONCODE}}</p>
          <div class="white-circle">

          </div>
          <div class="white-circle1">

          </div>
          <p class="vouchers-img-content"><b>{{data.COUPONTYPE}} :</b> {{data.DESCRIPTION}} <strong>{{data.COUPONCODE}}</strong> . Add Item to Avail Discount.</p>

        </div>
        <!-- <div class="vouchers-img">
          <p class="vouchers-img-heading">Receipt-Reward</p>
          <div class="white-circle">

          </div>
          <div class="white-circle1">

          </div>
          <p class="vouchers-img-content">Discount on receipt : 10% against 2 Reward type :<strong> Diamond</strong>. Buy More to Get Discount.</p>
            <br>
        </div>
          <div class="vouchers-img">
            <p class="vouchers-img-heading">MultipleItem</p>

            <div class="white-circle">

            </div>
            <div class="white-circle1">

            </div>
            <p class="vouchers-img-content">Discount on Polo, T-Shirt, Sunglasses items; 5% against 3 Reward type :<strong> Smiles</strong> . Add Item to Avail Discount.</p>

          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template let-modal class="modal fade bd-example-modal-lg" #info  tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div>
    <div class="modal-content">

      <div class="modal-body">
        <p style="text-align:right;cursor:pointer" (click)="modal.dismiss('Cross click')"><i class="fa fa-times fa-2x"></i></p>
        <p class="more-information">More Information :</p>
        <table class="modal-table">
          <tr>
            <td class="modal-body-bold">ADDRESS</td>
            <td class="aa">:</td>
            <td>{{address1}} {{address2}} {{city}} {{state}} {{country}} {{zip}}</td>
          </tr>
          <tr>
            <td class="modal-body-bold">MEMBER SINCE</td>
            <td class="aa">:</td>
            <td>{{createdDate | date}}</td>
          </tr>
          <!-- <tr>
            <td class="modal-body-bold">FIRST CHILD'S NAME</td>
            <td class="aa">:</td>
            <td>Value</td>
          </tr>
          <tr>
            <td class="modal-body-bold">FIRST CHILD'S BIRTHDAY</td>
            <td class="aa">:</td>
            <td>Value</td>
          </tr>
          <tr>
            <td class="modal-body-bold">SECOND CHILD'S NAME</td>
            <td class="aa">:</td>
            <td>Value/Zip</td>
          </tr>
          <tr>
            <td class="modal-body-bold">ANY UDF NAME</td>
            <td class="aa">:</td>
            <td>Value</td>
          </tr> -->
        </table>
      </div>

    </div>
  </div>
</ng-template>


<div class="modal fade bd-example-modal-lg1 " id="closea" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered bonus-dialog">
    <div class="modal-content">

      <div class="modal-body  bonus-modal">
      <p style="text-align:right;cursor:pointer" onclick="closeq()"><i class="fa fa-times fa-2x"></i></p>
        <p class="bonuses-available-red">Bonuses Available</p>
        <table class="bonus-table">
          <tr>
            <td style="text-align:center;font-weight:bold;font-size:25px;">Reasons</td>
            <td style="text-align:center;font-weight:bold;font-size:25px;">Bonus</td>
          </tr>
          <tr>
            <td class="">On reaching leve of <strong>$1000.00 </strong> of Lifetime purchase value</td>
            <td class="bonus-center-table">$10.00</td>
          </tr>
          <tr>
            <td class="">On reaching leve of <strong>$5000.00 </strong> of Lifetime purchase value</td>
            <td class="bonus-center-table">$50.00</td>
          </tr>
          <tr>
            <td class="">On reaching leve of <strong>$100.00 </strong> of Lifetime purchase value</td>
            <td class="bonus-center-table">$5.00</td>
          </tr>
          <tr>
            <td class="">On Enrollment</td>
            <td class="bonus-center-table">$5.00</td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</div>



<ng-template let-modal #transactionHistory class="modal fade bd-example-modal-lg2" id="transactiontotalmodal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div>
    <div class="modal-content">

      <div class="modal-body  transaction-modal">
        <p style="text-align:right;cursor:pointer" (click)="modal.dismiss('Cross click')"><i class="fa fa-times fa-2x"></i></p>
        <p class="transaction-history-red">Transaction History</p>
        <table class="transaction-table">
          <tr class="transaction-table-black-background">
            <td>Date</td>
            <td>Transaction</td>
            <td> Loyalty Earned</td>
            <td>Receipt No.</td>
            <td></td>
          </tr>
          <tbody class="transaction-body">
            <tr  *ngFor="let data of loyaltyMemberTransaction">
              <td class="transaction-date">{{data.date.substring(0, 10) | date}}</td>
              <td class="transaction-amount">{{data.amount.type}}</td>
              <td class="transaction-point"><span  *ngIf="data.balances[0].difference">{{data.balances[0].difference}} Points </span><span *ngIf="data.balances[1].difference">₹{{data.balances[1].difference}}</span>  </td>
              <td class="transaction-no">{{data.receiptNumber}}</td>
              <td class="transaction-receipt"><a  *ngIf="data.eReceiptURL" href="{{data.eReceiptURL}}" target="_blank">View Receipt</a><span *ngIf="!data.eReceiptURL" >NA</span></td>
            </tr>
          </tbody>
        </table>
        <div class="transaction-button-modal">
          <button type="button" class="btn btn-danger" data-toggle="modal" data-target=".bd-example-modal-lg3"  *ngIf="nextEnabled" (click)="next(loyaltyMemberTransaction)">Next Page</button>
          <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target=".bd-example-modal-lg3" *ngIf="prevEnabled"  (click)="prev(loyaltyMemberTransaction)">Previous Page</button>

        </div>

      </div>

    </div>
  </div>
</ng-template>

<div class="modal fade bd-example-modal-lg3" id="transactiontotalmodalsec" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered transaction-dialog">
    <div class="modal-content">

      <div class="modal-body  transaction-modal">
        <p style="text-align:right;cursor:pointer" onclick="closeq()"><i class="fa fa-times fa-2x"></i></p>
        <p class="transaction-history-red">Transaction History</p>
        <table class="transaction-table">
          <tr class="transaction-table-black-background">
            <td>Date</td>
            <td>Transaction</td>
            <td> Loyalty Earned</td>
            <td>Receipt No.</td>
            <td></td>
          </tr>
          <tbody class="transaction-body">
            <tr>
              <td class="transaction-date">18 Nov 2020</td>
              <td class="transaction-amount">$952.4</td>
              <td class="transaction-point">202 Points 73.1 Currency</td>
              <td class="transaction-no">110</td>
              <td class="transaction-receipt">View Receipt</td>
            </tr>
          </tbody>
        </table>
        <div class="transaction-button-modal">
          <button type="button" class="btn btn-danger" data-toggle="modal" data-target=".bd-example-modal-lg2" onclick="closeq()">Next Page</button>
          <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target=".bd-example-modal-lg2" onclick="closeq()">Previous Page</button>

        </div>

      </div>

    </div>
  </div>
</div>

<ng-template #tier class="modal fade bd-example-modal-lg4" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered teir-dialog">
    <div class="modal-content">

      <div class="modal-body  teir-modal">
        <p style="text-align:right;cursor:pointer" onclick="closeq()"><i class="fa fa-times fa-2x"></i></p>
        <table class="teir-table">
          <tr class="teir-table-black-background">
            <td>Privilege Teir</td>
            <td>Privilege Name</td>
            <td> Earn Rule</td>
            <td>Redeem Rule</td>
            <td>Teir Upgrade Rule</td>
          </tr>
          <tbody class="teir-body">
            <tr>
              <td class="teir-date">Teir 1</td>
              <td class="teir-amount">Silver</td>
              <td class="teir-point">1 Point for <strong> $1.00</strong></td>
              <td class="teir-no"> <strong>$10.00</strong>  for <strong> 100 </strong>points</td>
              <td class="teir-receipt">Cross-over at <strong>$10000.00</strong> </td>
            </tr>
            <tr>
              <td class="teir-date">Teir 2</td>
              <td class="teir-amount">Gold</td>
              <td class="teir-point">1 Point for <strong> $1.00</strong></td>
              <td class="teir-no"> <strong>$10.00</strong>  for <strong> 200 </strong>points</td>
              <td class="teir-receipt">Cross-over at <strong>$20000.00</strong> </td>
            </tr>
            <tr>
              <td class="teir-date">Teir 3</td>
              <td class="teir-amount">Platinum</td>
              <td class="teir-point">1 Point for <strong> $1.00</strong></td>
              <td class="teir-no"> <strong>$10.00</strong>  for <strong> 300 </strong>points</td>
              <td class="teir-receipt"></td>
            </tr>

          </tbody>
        </table>

      </div>

    </div>
  </div>
</ng-template>




<ng-template #content let-modal class="modal fade bd-example-modal-lg5" id="update-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div>
    <div class="modal-content">

      <div class="modal-body  form-modal">

        <form>
          <div class="AJ-profile">
            {{firstName.charAt(0)}}{{lastName.charAt(0)}}
          </div>
  <div class="form-row form-group form-modal-total-div">
    <div class="col">
      <input type="text" class="form-control" #updateFirstName placeholder="First name" value="{{firstName}}">
    </div>
    <div class="col">
      <input type="text" class="form-control" #updateLastName placeholder="Last name"  value="{{lastName}}">
    </div>
  </div>
  <div class="form-group">
  <input type="text" class="form-control" id="formGroupExampleInput" #updateMobileNumber placeholder="Mobile Number" value="{{mobile}}">
</div>
<div class="form-group">
  <input type="text" class="form-control" id="formGroupExampleInput2" #updateEmailAddress placeholder="Email Address" value="{{emailAddress}}">
</div>
<div class="form-row">
  <div class="col">
    <input type="text" class="form-control" #updateDOB placeholder="DOB in {{dobformat}}" value="{{birthDay | date: 'MM/dd/yyyy'}}">
  </div>
  <div class="col">
    <input type="text" class="form-control" #updateAnniveraryDate placeholder="Anniverary Date in {{anniversaryFormat}}" value="{{anniversary | date: 'MM/dd/yyyy'}}">
  </div>
</div>
<p class="additional-information">Additional Information :</p>
<div class="form-group">
<input type="text" class="form-control" #updateAddressLine1  id="formGroupExampleInput" placeholder="Address Line 1" value="{{address1}}">
</div>
<div class="form-group">
<input type="text" class="form-control" #updateAddressLine2 id="formGroupExampleInput2" placeholder="Address Line 2" value="{{address2}}">
</div>
<div class="form-row form-group">
<div class="col">
  <input type="text" class="form-control"  #updateCity placeholder="City" value="{{city}}">
</div>
<div class="col">
  <input type="text" class="form-control" #updateState placeholder="State" value="{{state}}">
</div>
</div>
<div class="form-row form-group">
<div class="col">
  <input type="text" class="form-control"  #updateCountry placeholder="Country" value="{{country}}">
</div>
<div class="col">
  <input type="text" class="form-control"  #updateZIP placeholder="Postal Code/ZIP" value="{{zip}}">
</div>
</div>
<p class="additional-information">More Information :</p>
<div class="form-row form-group">
<div class="col">
<input type="text" class="form-control" placeholder="First Child's Name">
</div>
<div class="col">
<input type="text" class="form-control" placeholder="First Child's Birthday">
</div>
</div>
<div class="form-row form-group">
<div class="col">
<input type="text" class="form-control" placeholder="Second Child's Name">
</div>
<div class="col">
<input type="text" class="form-control" placeholder="Any UDF Name">
</div>
</div>
<p class="modal-form-bottom-p">Member Since :{{createdDate | date: 'MMMM d, y'}}</p>
<h3 *ngIf="proUpresp" class="text-center">{{proUpresp}}</h3>
<div class="modal-form-button">
<button type="button" class="btn btn-outline-primary" (click)="modal.dismiss('cancel click')">Cancel</button>
<button type="button" class="btn btn-danger" (click)="updateContacts(updateFirstName,updateLastName
,updateMobileNumber,updateEmailAddress,updateDOB,updateAnniveraryDate,updateAddressLine1,updateAddressLine2,updateCity,updateState,updateCountry,updateZIP)">Save</button>

</div>
</form>

      </div>
    </div>
  </div>
</ng-template>

  <script type="text/javascript">

    function cancel(){
      document.getElementById("update-modal").classList.remove("show");
      $('.modal-backdrop').remove();
    }
    function closeq(){
      $('.modal').modal('hide');
    }
  </script>

  <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
</body>

</html>
