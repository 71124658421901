<form id="myForm">
    <div id="model-content">
      <!-- <div class="modal-header">
            <div class="row">
                <h1>OptCulture</h1>
            </div>
        </div> -->
      <div class="modal-body inheritOverflow pt-0 pb-0">
        <div class="row">
          <div class="brand">
              <div class="hello">
              </div>
            <h3>GAP</h3>
            <fa-icon (click)="logOut()" class="angle" [icon]="faSignOut"></fa-icon> 
          </div>
          <div class="col-12">
            <div class="row">
                <div class="col-12 text-center">
                <img src="./assets/images/logo.png" />
                </div>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-12">
            <div class="cam" >
                <div class="col-12" >
                
                    <div class="scanner-shell" [hidden]="!hasDevices">
                        <header>
                            <select *ngIf="scannerEnabled"
                              matNativeControl
                              matInput
                              (change)="onDeviceSelectChange($event.target.value)"
                            >
                              <option value="" [selected]="!currentDevice">No Device Selected</option>
                              <option
                                *ngFor="let device of availableDevices"
                                [value]="device.deviceId"
                                [selected]="
                                  currentDevice && device.deviceId === currentDevice.deviceId
                                "
                              >
                                {{ device.label }}
                              </option>
                            </select>
                        </header>
                      
                        <zxing-scanner
                          [torch]="torchEnabled"
                          (scanSuccess)="onCodeResult($event)"
                          [formats]="formatsEnabled"
                          [(device)] = "scanner.device"
                          [scannerEnabled] = "scannerEnabled"
                          [tryHarder]="tryHarder"
                          (permissionResponse)="onHasPermission($event)"
                          (camerasFound)="onCamerasFound($event)"
                          (torchCompatible)="onTorchCompatible($event)"
                          #scanner
                        ></zxing-scanner>
                      
                        <section class="results" *ngIf="qrResultString">
                          <div>
                            <small>Result</small>
                            <strong>{{ qrResultString }}</strong>
                          </div>
                          <button mat-icon-button (click)="clearResult()">&times;</button>
                        </section>
                      </div>
                      
                      <ng-container *ngIf="hasPermission === undefined">
                        <h2>Waiting for permissions.</h2>
                      
                        <blockquote>
                          If your device does not has cameras, no permissions will be asked.
                        </blockquote>
                      </ng-container>
                      
                      <ng-container *ngIf="hasPermission === false">
                        <h2>
                          You denied the camera permission, we can't scan anything without it. 😪
                        </h2>
                      </ng-container>
                      
                      <ng-container *ngIf="hasDevices === undefined">
                        <h2>Couldn't check for devices.</h2>
                      
                        <blockquote>This may be caused by some security error.</blockquote>
                      </ng-container>
                      
                      <ng-container *ngIf="hasDevices === false">
                        <h2>No devices were found.</h2>
                      
                        <blockquote>
                          I believe your device has no media devices attached to.
                        </blockquote>
                      </ng-container>
                      
                </div>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
      <div class="modal-footer">
        <div class="submit">
          <button
          type="submit"
          (click)="openCam()"
          class="btn btn-primary mb-2"
        ><fa-icon [icon]="['fas', 'barcode']"></fa-icon>
          Scan Barcode
        </button>
        </div>
        <div class="col-12 text-center">
          or enter mobile/membership number below
        </div>
        <div class="entermobile">
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Mobile/membership Number" aria-label="Mobile Number" #MobileNumber aria-describedby="button-addon2">
          <div class="input-group-append">
            <button class="btn btn-outline-primary" type="button" id="button-addon2" (click)="enterMobile(MobileNumber)">Enter</button>
          </div>
        </div>
        </div>

        <div class="col-12 text-center mt-5">
          <button class="btn btn-secondary btn-lg mt-5" type="button" id="button-addon2" (click)="doReset()">Reset</button>
        </div>
      </div>
    </div>
  </form>
  
