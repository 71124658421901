import { Component, OnInit } from '@angular/core';
import {MatRadioModule} from '@angular/material/radio'; 
declare var jQuery: any;


@Component({
  selector: 'app-add-update',
  templateUrl: './add-update.component.html',
  styleUrls: ['./add-update.component.css']
})
export class AddUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   
  }

}
